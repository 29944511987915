<template>
<div>
<div class="banner-image">
        <div class="banner__overlay"></div>
        <h1>Mentions légales </h1>
    </div>

<div class="wrapper-legals">
    <p class="mentions-legales">
        <section class="pdf_page" aria-label="Page 1">
<div class="textlayer">
<div><strong>D&eacute;finitions</strong></div>
<div>&nbsp;</div>
<div><strong>Client :</strong></div>
<div>Tout professionnel ou personne physique capable au sens des articles 1123 et suivants du Code civil, ou personne morale, qui visite le Site objet des pr&eacute;sentes conditions g&eacute;n&eacute;rales.</div>
<div>&nbsp;</div>
<div><strong>Prestations et Services :</strong> lacombe-peinture.fr met &agrave; disposition des Clients :</div>
<div>&nbsp;</div>
<div><strong>Contenu :</strong></div>
<div>Ensemble des &eacute;l&eacute;ments constituants l&rsquo;information pr&eacute;sente sur le Site, notamment textes &ndash; images &ndash; vid&eacute;os.</div>
<div>&nbsp;</div>
<div><strong>Informations clients :</strong></div>
<div>Ci apr&egrave;s d&eacute;nomm&eacute; &laquo; Information (s)&raquo; qui correspondent &agrave; l&rsquo;ensemble des donn&eacute;es personnelles susceptibles d&rsquo;&ecirc;tre d&eacute;tenues par lacombe-peinture.fr pour la gestion de votre compte, de la gestion de la relation client et &agrave; des fins d&rsquo;analyses et de statistiques.</div>
<div>&nbsp;</div>
<div><strong>Utilisateur :</strong></div>
<div>Internaute se connectant, utilisant le site susnomm&eacute;.</div>
<div>&nbsp;</div>
<div><strong>Informations personnelles :</strong></div>
<div>&laquo; Les informations qui permettent, sous quelque forme que ce soit, directement ou non, l'identification des personnes physiques auxquelles elles s'appliquent &raquo; (article 4 de la loi n&deg;78-17 du 6 janvier 1978). Les termes &laquo; donn&eacute;es &agrave; caract&egrave;re personnel &raquo;, &laquo; personne concern&eacute;e &raquo;,&laquo; sous traitant &raquo; et &laquo; donn&eacute;es sensibles &raquo; ont le sens d&eacute;fini par le R&egrave;glement G&eacute;n&eacute;ral sur la Protection des Donn&eacute;es (RGPD : n&deg; 2016-679)</div>
<div>&nbsp;</div>
<div><strong>1. Pr&eacute;sentation du site internet.</strong></div>
<div>&nbsp;</div>
<div>En vertu de l'article 6 de la loi n&deg; 2004-575 du 21 juin 2004 pour la confiance dans l'&eacute;conomie num&eacute;rique, il est pr&eacute;cis&eacute; aux utilisateurs du siteinternet lacombe-peinture.fr l'identit&eacute; des diff&eacute;rents intervenants dans le cadre de sa r&eacute;alisation et de son suivi:</div>
<div>&nbsp;</div>
<div><strong>Propri&eacute;taire :&nbsp;</strong>SARL Lacombe Capital social de 50000&euro; Num&eacute;ro de TVA:</div>
<div>FR03384468906 &ndash; 3, avenue Georges Cl&eacute;menceau 81600 Gaillac</div>
<div>&nbsp;</div>
</div>
</section>
<section class="pdf_page" aria-label="Page 11">
<div class="textlayer">
<div><strong>Responsable publication :&nbsp;SARL LACOMBE</strong> &ndash; ets.gaillac@lacombe-peinture.fr</div>
<div>&nbsp;</div>
<div><strong>Webmaster</strong> : Martin Blanquer &ndash; <a href="mailto:martin@eneko.co">martin@eneko.co</a></div>
<div>&nbsp;</div>
<div><strong>H&eacute;bergeur</strong> : OVH &ndash; 2 rue Kellermann 59100 Roubaix 1007</div>
<div>&nbsp;</div>
<div><strong>D&eacute;l&eacute;gu&eacute; &agrave; la protection des donn&eacute;es</strong> : Marie-Pierre BLANQUER &ndash; <a href="mailto:ets.gaillac@lacombe-peinture.fr">ets.gaillac@lacombe-peinture.fr</a></div>
<div>&nbsp;</div>
<div>
<section class="pdf_page" aria-label="Page 2">
<div class="textlayer">
<div><strong>2. Conditions g&eacute;n&eacute;rales d&rsquo;utilisation du site et des services propos&eacute;s.</strong></div>
<div>&nbsp;</div>
<div class="container__legal">
<div>Le Site constitue une &oelig;uvre de l&rsquo;esprit prot&eacute;g&eacute;e par les dispositions du</div>
<div>Code de la Propri&eacute;t&eacute; Intellectuelle et des R&eacute;glementations Internationales</div>
<div>applicables. Le Client ne peut en aucune mani&egrave;re r&eacute;utiliser, c&eacute;der ou</div>
<div>exploiter pour son propre compte tout ou partie des &eacute;l&eacute;ments ou travaux du</div>
<div>Site. L&rsquo;utilisation du site lacombe-peinture.fr implique l&rsquo;acceptation pleine et</div>
<div>enti&egrave;re des conditions g&eacute;n&eacute;rales d&rsquo;utilisation ci-apr&egrave;s d&eacute;crites. Ces</div>
<div>conditions d&rsquo;utilisation sont susceptibles d&rsquo;&ecirc;tre modifi&eacute;es ou compl&eacute;t&eacute;es &agrave;</div>
<div>tout moment, les utilisateurs du site lacombe-peinture.fr</div>
<div>sont donc invit&eacute;s &agrave; les consulter de mani&egrave;re r&eacute;guli&egrave;re.</div>
<div>Ce site internet est normalement accessible &agrave; tout moment aux utilisateurs.</div>
<div>Une interruption pour raison de maintenance technique peut &ecirc;tre toutefois</div>
<div>d&eacute;cid&eacute;e par lacombe-peinture.fr, qui s&rsquo;efforcera alors de communiquer</div>
<div>pr&eacute;alablement aux utilisateurs les dates et heures de l&rsquo;intervention.</div>
<div>Le site web lacombe-peinture.fr est mis &agrave; jour r&eacute;guli&egrave;rement par</div>
<div>lacombe-peinture.fr responsable. De la m&ecirc;me fa&ccedil;on, les mentions l&eacute;gales peuvent &ecirc;tre modifi&eacute;es &agrave; tout moment : elles s&rsquo;imposent n&eacute;anmoins &agrave; l&rsquo;utilisateur qui est invit&eacute; &agrave; s&rsquo;y r&eacute;f&eacute;rer le plus souvent possible afin d&rsquo;en prendre connaissance.</div></div>
<div>&nbsp;</div>
<div><strong>3. Description des services fournis.</strong></div>
<div>&nbsp;</div>
<div class="container__legal">
<div>Le site internet lacombe-peinture.fr a pour objet de fournir une information</div>
<div>concernant l&rsquo;ensemble des activit&eacute;s de la soci&eacute;t&eacute; lacombe-peinture s&rsquo;efforce de fournir sur le site lacombe-peinture.fr des informations aussi</div></div>
</div>
</section>
<section class="pdf_page" aria-label="Page 3">
<div class="textlayer">
    <div class="container__legal">
<div>pr&eacute;cises que possible. Toutefois, il ne pourra &ecirc;tre tenu responsable des</div>
<div>oublis, des inexactitudes et des carences dans la mise &agrave; jour, qu&rsquo;elles soient</div>
<div>de son fait ou du fait des tiers partenaires qui lui fournissent ces</div>
<div>informations.</div>
<div>Toutes les informations indiqu&eacute;es sur le site lacombe-peinture.fr sont</div>
<div>donn&eacute;es &agrave; titre indicatif, et sont susceptibles d&rsquo;&eacute;voluer. Par ailleurs, les</div>
<div>renseignements figurant sur le site lacombe-peinture.fr ne sont pas</div>
<div>exhaustifs. Ils sont donn&eacute;s sous r&eacute;serve de modifications ayant &eacute;t&eacute;</div>
<div>apport&eacute;es depuis leur mise en ligne.</div></div>
<div>&nbsp;</div>
<div><strong>4. Limitations contractuelles sur les donn&eacute;es techniques.</strong></div>
<div>&nbsp;</div>
<div class="container__legal">
<div>Le site utilise la technologie JavaScript. Le site Internet ne pourra &ecirc;tre tenu</div>
<div>responsable de dommages mat&eacute;riels li&eacute;s &agrave; l&rsquo;utilisation du site. De plus,</div>
<div>l&rsquo;utilisateur du site s&rsquo;engage &agrave; acc&eacute;der au site en utilisant un mat&eacute;riel r&eacute;cent,</div>
<div>ne contenant pas de virus et avec un navigateur de derni&egrave;re g&eacute;n&eacute;ration mis-</div>
<div>&agrave;-jour.</div>
<div>Le site lacombe-peinture.fr est h&eacute;berg&eacute; chez un prestataire sur le</div>
<div>territoire de l&rsquo;Union Europ&eacute;enne conform&eacute;ment aux dispositions du</div>
<div>R&egrave;glement G&eacute;n&eacute;ral sur la Protection des Donn&eacute;es (RGPD: n&deg; 2016-679).</div>
<div>L&rsquo;objectif est d&rsquo;apporter une prestation qui assure le meilleur taux</div>
<div>d&rsquo;accessibilit&eacute;. L&rsquo;h&eacute;bergeur assure la continuit&eacute; de son service 24 Heures sur</div>
<div>24, tous les jours de l&rsquo;ann&eacute;e. Il se r&eacute;serve n&eacute;anmoins la possibilit&eacute;</div>
<div>d&rsquo;interrompre le service d&rsquo;h&eacute;bergement pour les dur&eacute;es les plus courtes</div>
<div>possibles notamment &agrave; des fins de maintenance, d&rsquo;am&eacute;lioration de ses</div>
<div>infrastructures, de d&eacute;faillance de ses infrastructures ou si les Prestations et</div>
<div>Services g&eacute;n&egrave;rent un trafic r&eacute;put&eacute; anormal. lacombe-peinture.fr</div>
<div>et l&rsquo;h&eacute;bergeur ne pourront &ecirc;tre tenus responsables en cas de dysfonctionnement du r&eacute;seau Internet, des lignes t&eacute;l&eacute;phoniques ou</div>
<div>du mat&eacute;riel informatique et de t&eacute;l&eacute;phonie li&eacute; notamment &agrave; l&rsquo;encombrement</div>
<div>du r&eacute;seau emp&ecirc;chant l&rsquo;acc&egrave;s au serveur.</div></div>
<div>&nbsp;</div>
<div><strong>5. Propri&eacute;t&eacute; intellectuelle et contrefa&ccedil;ons.</strong></div>
<div>&nbsp;</div>
<div>Lacombe-peinture.fr est propri&eacute;taire des droits de propri&eacute;t&eacute; intellectuelle et</div>
<div>d&eacute;tient les droits d&rsquo;usage sur tous les &eacute;l&eacute;ments accessibles sur le site</div>
<div>internet, notamment les textes, images, graphismes, logos, vid&eacute;os, ic&ocirc;nes et sons. Toute reproduction, repr&eacute;sentation, modification, publication,</div>
</div>
</section>
<section class="pdf_page" aria-label="Page 4">
<div class="textlayer">
    <div class="container__legal">
<div>adaptation de tout ou partie des &eacute;l&eacute;ments du site, quel que soit le moyen ou</div>
<div>le proc&eacute;d&eacute; utilis&eacute;, est interdite, sauf autorisation &eacute;crite pr&eacute;alable de :</div>
<div>lacombe-peinture.fr.</div>
<div>Toute exploitation non autoris&eacute;e du site ou de l&rsquo;un quelconque des &eacute;l&eacute;ments</div>
<div>qu&rsquo;il contient sera consid&eacute;r&eacute;e comme constitutive d&rsquo;une contrefa&ccedil;on et</div>
<div>poursuivie conform&eacute;ment aux dispositions des articles L.335-2 et suivants</div>
<div>du Code de Propri&eacute;t&eacute; Intellectuelle.</div></div>
<div>&nbsp;</div>
<div><strong>6. Limitations de responsabilit&eacute;.</strong></div>
<div>&nbsp;</div>
<div class="container__legal">
<div>Lacombe-peinture.fr agit en tant qu&rsquo;&eacute;diteur du site.</div>
<div>Lacombe-peinture.fr est responsable de la qualit&eacute; et de la v&eacute;racit&eacute; du Contenu qu&rsquo;il publie.</div>
<div>Lacombe-peinture.fr ne pourra &ecirc;tre tenu responsable des dommages directs</div>
<div>et indirects caus&eacute;s au mat&eacute;riel de l&rsquo;utilisateur, lors de l&rsquo;acc&egrave;s au site internet</div>
<div>lacombe-peinture.fr, et r&eacute;sultant soit de l&rsquo;utilisation d&rsquo;un mat&eacute;riel ne</div>
<div>r&eacute;pondant pas aux sp&eacute;cifications indiqu&eacute;es au point 4, soit de l&rsquo;apparition</div>
<div>d&rsquo;un bug ou d&rsquo;une incompatibilit&eacute;.</div>
<div>Lacombe-peinture.fr ne pourra &eacute;galement &ecirc;tre tenu responsable des</div>
<div>dommages indirects (tels par exemple qu&rsquo;une perte de march&eacute; ou perte</div>
<div>d&rsquo;une chance) cons&eacute;cutifs &agrave; l&rsquo;utilisation du site lacombe-peinture.fr. Des</div>
<div>espaces interactifs (possibilit&eacute; de poser des questions dans l&rsquo;espace</div>
<div>contact) sont &agrave; la disposition des utilisateurs.</div>
<div>Lacombe-peinture.fr se r&eacute;serve le droit de supprimer, sans mise en demeure pr&eacute;alable, tout contenu d&eacute;pos&eacute; dans cet espace qui contreviendrait &agrave; la l&eacute;gislation applicable en France, en</div>
<div>particulier aux dispositions relatives &agrave; la protection des donn&eacute;es. Le cas</div>
<div>&eacute;ch&eacute;ant, lacombe-peinture.fr se r&eacute;serve &eacute;galement la possibilit&eacute; de mettre</div>
<div>en cause la responsabilit&eacute; civile et/ou p&eacute;nale de l&rsquo;utilisateur, notamment en</div>
<div>cas de message &agrave; caract&egrave;re raciste, injurieux, diffamant, ou pornographique,</div>
<div>quel que soit le support utilis&eacute; (texte, photographie ...).</div></div>
<div>&nbsp;</div>
<div><strong>7. Gestion des donn&eacute;es personnelles.</strong></div>
<div>&nbsp;</div>
<div>Le Client est inform&eacute; des r&eacute;glementations concernant la communication</div>
<div>marketing, la loi du 21 Juin 2014 pour la confiance dans l&rsquo;Economie</div>
<div>Num&eacute;rique, la Loi Informatique et Libert&eacute; du 06 Ao&ucirc;t 2004 ainsi que du</div>
<div>R&egrave;glement G&eacute;n&eacute;ral sur la Protection des Donn&eacute;es (RGPD : n&deg; 2016-679).</div>
<div>&nbsp;</div>
<div><strong>7.1 Responsables de la collecte des donn&eacute;es personnelles</strong></div>
</div>
</section>
<section class="pdf_page" aria-label="Page 5">
<div class="textlayer">
    <div class="container__legal">
<div>Pour les Donn&eacute;es Personnelles collect&eacute;es dans le cadre de la cr&eacute;ation du</div>
<div>compte personnel de l&rsquo;Utilisateur et de sa navigation sur le Site, le</div>
<div>responsable du traitement des Donn&eacute;es Personnelles est : SARL Lacombe.</div>
<div>lacombe-peinture.fr est repr&eacute;sent&eacute; par M. Eric Lacombe, son repr&eacute;sentant</div>
<div>l&eacute;gal. En tant que responsable du traitement des donn&eacute;es qu&rsquo;il collecte,</div>
<div>lacombe-peinture.fr s&rsquo;engage &agrave; respecter le cadre des dispositions l&eacute;gales en vigueur.</div>
<div>Il appartient notamment au Client d&rsquo;&eacute;tablir les finalit&eacute;s de ses traitements de</div>
<div>donn&eacute;es, de fournir &agrave; ses prospects et clients, &agrave; partir de la collecte de leurs</div>
<div>consentements, une information compl&egrave;te sur le traitement de leurs donn&eacute;es</div>
<div>personnelles et de maintenir un registre des traitements conforme &agrave; la</div>
<div>r&eacute;alit&eacute;. Chaque fois que lacombe-peinture.fr traite des Donn&eacute;es Personnelles,</div>
<div>lacombe-peinture.fr prend toutes les mesures raisonnables</div>
<div>pour s&rsquo;assurer de l&rsquo;exactitude et de la pertinence des Donn&eacute;es Personnelles</div>
<div>au regard des finalit&eacute;s pour lesquelles lacombe-peinture.fr</div>
<div>les traite.</div>
</div>
<div>&nbsp;</div>
<div><strong>7.2 Finalit&eacute; des donn&eacute;es collect&eacute;es</strong></div>
<div class="container__legal">
<div>lacombe-peinture.fr est susceptible de traiter tout ou partie des donn&eacute;es :</div>
<div>&bull; Pour permettre la navigation sur le Site et la gestion et la tra&ccedil;abilit&eacute; des</div>
<div>prestations et services command&eacute;s par l&rsquo;utilisateur : donn&eacute;es de connexion</div>
<div>et d&rsquo;utilisation du Site, facturation, historique des commandes, etc.</div>
<div>&bull; Pour pr&eacute;venir et lutter contre la fraude informatique (spamming,</div>
<div>hacking...) : mat&eacute;riel informatique utilis&eacute; pour la navigation, l&rsquo;adresse IP, le</div>
<div>mot de passe (hash&eacute;)</div>
<div>&bull; Pour am&eacute;liorer la navigation sur le Site : donn&eacute;es de connexion et</div>
<div>d&rsquo;utilisation</div>
<div>&bull; Pour mener des enqu&ecirc;tes de satisfaction facultatives surlacombe-peinture.fr : adresse email</div>
<div>&bull; Pour mener des campagnes de communication (sms, mail) : num&eacute;ro de</div>
<div>t&eacute;l&eacute;phone, adresse email</div>
<div>&bull; lacombe-peinture.fr ne commercialise pas vos donn&eacute;es personnelles qui</div>
<div>sont donc uniquement utilis&eacute;es par n&eacute;cessit&eacute; ou &agrave; des fins statistiques et</div>
<div>d&rsquo;analyses.</div>
</div>
<div>&nbsp;</div>
<div><strong>7.3 Droit d&rsquo;acc&egrave;s, de rectification et d&rsquo;opposition</strong></div>
</div>
</section>
<section class="pdf_page" aria-label="Page 6">
<div class="textlayer">
    <div class="container__legal">
<div>Conform&eacute;ment &agrave; la r&eacute;glementation europ&eacute;enne en vigueur, les Utilisateurs de</div>
<div>lacombe-peinture.fr disposent des droits suivants :</div>
<div>&bull; Droit d'acc&egrave;s (article 15 RGPD) et de rectification (article 16 RGPD), de</div>
<div>mise &agrave; jour, de compl&eacute;tude des donn&eacute;es des Utilisateurs droit de verrouillage</div>
<div>ou d&rsquo;effacement des donn&eacute;es des Utilisateurs &agrave; caract&egrave;re personnel (article</div>
<div>17 du RGPD), lorsqu&rsquo;elles sont inexactes, incompl&egrave;tes, &eacute;quivoques,</div>
<div>p&eacute;rim&eacute;es, ou dont la collecte, l'utilisation, la communication ou la</div>
<div>conservation est interdite</div>
<div>&bull; Droit de retirer &agrave; tout moment un consentement (article 13-2c RGPD)</div>
<div>&bull; Droit &agrave; la limitation du traitement des donn&eacute;es des Utilisateurs (article 18</div>
<div>RGPD)</div>
<div>&bull; Droit d&rsquo;opposition au traitement des donn&eacute;es des Utilisateurs (article 21</div>
<div>RGPD)</div>
<div>&bull; Droit &agrave; la portabilit&eacute; des donn&eacute;es que les Utilisateurs auront fournies,</div>
<div>lorsque ces donn&eacute;es font l&rsquo;objet de traitements automatis&eacute;s fond&eacute;s sur leur</div>
<div>consentement ou sur un contrat (article 20 RGPD)</div>
<div>&bull; Droit de d&eacute;finir le sort des donn&eacute;es des Utilisateurs apr&egrave;s leur mort et de</div>
<div>choisir &agrave; qui lacombe-peinture.fr devra communiquer (ou non) ses donn&eacute;es &agrave;</div>
<div>un tiers qu&rsquo;ils aura pr&eacute;alablement d&eacute;sign&eacute;.</div>
<div>D&egrave;s que lacombe-peinture.fr a connaissance du d&eacute;c&egrave;s d&rsquo;un Utilisateur et &agrave;</div>
<div>d&eacute;faut d&rsquo;instructions de sa part, lacombe-peinture.fr s&rsquo;engage &agrave; d&eacute;truire ses donn&eacute;es, sauf si leur conservation s&rsquo;av&egrave;re n&eacute;cessaire &agrave; des fins probatoires</div>
<div>ou pour r&eacute;pondre &agrave; une obligation l&eacute;gale.</div>
<div>Si l&rsquo;Utilisateur souhaite savoir comment lacombe-peinture.fr utilise ses</div>
<div>Donn&eacute;es Personnelles, demander &agrave; les rectifier ou s&rsquo;oppose &agrave; leur traitement, l&rsquo;Utilisateur peut contacter lacombe-peinture.fr par &eacute;crit &agrave;</div>
<div>l&rsquo;adresse suivante : SARL Lacombe &ndash; DPO, Marie-Pierre BLANQUER 3, avenue Georges Cl&eacute;menceau 81600 Gaillac</div>
<div>&nbsp;</div>
<div>Dans ce cas, l&rsquo;Utilisateur doit indiquer les Donn&eacute;es Personnelles qu&rsquo;il</div></div>
</div>
</section>
<section class="pdf_page" aria-label="Page 7">
<div class="textlayer">
    <div class="container__legal">
<div>souhaiterait que lacombe-peinture.fr corrige, mette &agrave; jour ou supprime, en</div>
<div>s&rsquo;identifiant pr&eacute;cis&eacute;ment avec une copie d&rsquo;une pi&egrave;ce d&rsquo;identit&eacute; (carte</div>
<div>d&rsquo;identit&eacute; ou passeport).</div>
<div>Les demandes de suppression de Donn&eacute;es Personnelles seront soumises</div>
<div>aux obligations qui sont impos&eacute;es &agrave; lacombe-peinture.fr</div>
<div>par la loi, notamment en mati&egrave;re de conservation ou d&rsquo;archivage des documents.</div>
<div>Enfin, les Utilisateurs de lacombe-peinture.fr peuvent d&eacute;poser une</div>
<div>r&eacute;clamation aupr&egrave;s des autorit&eacute;s de contr&ocirc;le, et notamment de la CNIL</div>
<div>(<a href="https://www.cnil.fr/fr/plaintes">https://www.cnil.fr/fr/plaintes</a>).</div></div>
<div>&nbsp;</div>
<div><strong>7.4 Non-communication des donn&eacute;es personnelles</strong></div>
<div class="container__legal">
<div>lacombe-peinture.fr s&rsquo;interdit de traiter, h&eacute;berger ou transf&eacute;rer les</div>
<div>Informations collect&eacute;es sur ses Clients vers un pays situ&eacute; en dehors de</div>
<div>l&rsquo;Union europ&eacute;enne ou reconnu comme &laquo;non ad&eacute;quat &raquo; par la Commission</div>
<div>europ&eacute;enne sans en informer pr&eacute;alablement le client.</div>
<div>Pour autant, lacombe-peinture.fr reste libre du choix de ses sous-traitants techniques et commerciaux &agrave; la condition qu&rsquo;il pr&eacute;sentent les garanties suffisantes au regard des exigences du R&egrave;glement G&eacute;n&eacute;ral sur la Protection des Donn&eacute;es (RGPD: n&deg; 2016-679).</div>
<div>Lacombe-peinture.fr s&rsquo;engage &agrave; prendre toutes les pr&eacute;cautions n&eacute;cessaires</div>
<div>afin de pr&eacute;server la s&eacute;curit&eacute; des Informations et notamment qu&rsquo;elles ne</div>
<div>soient pas communiqu&eacute;es &agrave; des personnes non autoris&eacute;es. Cependant, si</div>
<div>un incident impactant l&rsquo;int&eacute;grit&eacute; ou la confidentialit&eacute; des Informations du</div>
<div>Client est port&eacute;e &agrave; la connaissance de lacombe-peinture.fr, celle-ci devra</div>
<div>dans les meilleurs d&eacute;lais informer le Client et lui communiquer les mesures</div>
<div>de corrections prises. Par ailleurs lacombe-peinture.fr</div>
<div>ne collecte aucune &laquo; donn&eacute;es sensibles &raquo;.</div>
<div>Les Donn&eacute;es Personnelles de l&rsquo;Utilisateur peuvent &ecirc;tre trait&eacute;es par des</div>
<div>filiales de lacombe-peinture.fr et des sous-traitants (prestataires de services),</div>
<div>exclusivement afin de r&eacute;aliser les finalit&eacute;s de la pr&eacute;sente politique.</div>
<div>Dans la limite de leurs attributions respectives et pour les finalit&eacute;s rappel&eacute;es</div>
<div>ci-dessus, les principales personnes susceptibles d&rsquo;avoir acc&egrave;s aux donn&eacute;es des Utilisateurs de lacombe-peinture.fr sont principalement les agents de</div></div>
</div>
</section>
<section class="pdf_page" aria-label="Page 8">
<div class="textlayer">
<div>notre service client.</div>
<div>&nbsp;</div>
<div><strong>7.5 Types de donn&eacute;es collect&eacute;es</strong></div>
<div class="container__legal">
<div>Concernant les utilisateurs d&rsquo;un Site lacombe-peinture.fr, nous collectons les</div>
<div>donn&eacute;es suivantes qui sont indispensables au fonctionnement du service, et</div>
<div>qui seront conserv&eacute;es pendant une p&eacute;riode maximale de 60 mois mois apr&egrave;s</div>
<div>la fin de la relation contractuelle: Nom, Num&eacute;ro de T&eacute;l&eacute;phone, Adresse</div>
<div>email.</div></div>
<div>&nbsp;</div>
<div><strong>8. Notification d&rsquo;incident</strong></div>
<div class="container__legal">
<div>Quels que soient les efforts fournis, aucune m&eacute;thode de transmission sur</div>
<div>Internet et aucune m&eacute;thode de stockage &eacute;lectronique n'est compl&egrave;tement</div>
<div>s&ucirc;re. Nous ne pouvons en cons&eacute;quence pas garantir une s&eacute;curit&eacute; absolue.</div>
<div>Si nous prenions connaissance d'une br&egrave;che de la s&eacute;curit&eacute;, nous avertirions</div>
<div>les utilisateurs concern&eacute;s afin qu'ils puissent prendre les mesures</div>
<div>appropri&eacute;es. Nos proc&eacute;dures de notification d&rsquo;incident tiennent compte de</div>
<div>nos obligations l&eacute;gales, qu'elles se situent au niveau national ou europ&eacute;en.</div>
<div>Nous nous engageons &agrave; informer pleinement nos clients de toutes les</div>
<div>questions relevant de la s&eacute;curit&eacute; de leur compte et &agrave; leur fournir toutes les</div>
<div>informations n&eacute;cessaires pour les aider &agrave; respecter leurs propres obligations</div>
<div>r&eacute;glementaires en mati&egrave;re de reporting.</div>
<div>Aucune information personnelle de l'utilisateur du site lacombe-peinture.fr</div>
<div>n'est publi&eacute;e &agrave; l'insu de l'utilisateur, &eacute;chang&eacute;e, transf&eacute;r&eacute;e, c&eacute;d&eacute;e</div>
<div>ou vendue sur un support quelconque &agrave; des tiers. Seule l'hypoth&egrave;se du</div>
<div>rachat de lacombe-peinture.fr et de ses droits permettrait la transmission</div>
<div>des dites informations &agrave; l'&eacute;ventuel acqu&eacute;reur qui serait &agrave; son tour tenu de la</div>
<div>m&ecirc;me obligation de conservation et de modification des donn&eacute;es vis &agrave; vis</div>
<div>de l'utilisateur du site lacombe-peinture.fr.</div></div>
<div>&nbsp;</div>
<div><strong>S&eacute;curit&eacute;</strong></div>
<div>Pour assurer la s&eacute;curit&eacute; et la confidentialit&eacute; des Donn&eacute;es Personnelles et des</div>
</div>
</section>
<section class="pdf_page" aria-label="Page 9">
<div class="textlayer">
    <div class="container__legal">
<div>Donn&eacute;es Personnelles de Sant&eacute;, lacombe-peinture.fr utilise des r&eacute;seaux</div>
<div>prot&eacute;g&eacute;s par des dispositifs standards tels que par pare-feu, la</div>
<div>pseudonymisation, l&rsquo;encryption et mot de passe. Lors du traitement des Donn&eacute;es Personnelles, lacombe-peinture.fr prend toutes les mesures raisonnables visant &agrave; les prot&eacute;ger contre toute perte,</div>
<div>utilisation d&eacute;tourn&eacute;e, acc&egrave;s non autoris&eacute;, divulgation, alt&eacute;ration ou</div>
<div>destruction.</div></div>
<div>&nbsp;</div>
<div><strong>9. Liens hypertextes &laquo; cookies &raquo; et balises (&ldquo;tags&rdquo;) internet</strong></div>
<div class="container__legal">
<div>Le site lacombe-peinture.fr</div>
<div>contient un certain nombre de liens hypertextes</div>
<div>vers d&rsquo;autres sites, mis en place avec l&rsquo;autorisation de</div>
<div>lacombe-peinture.fr. Cependant, lacombe-peinture.fr n&rsquo;a pas la possibilit&eacute; de v&eacute;rifier le contenu des sites ainsi visit&eacute;s, et n&rsquo;assumera en cons&eacute;quence aucune responsabilit&eacute; de ce fait.</div>
<div>Sauf si vous d&eacute;cidez de d&eacute;sactiver les cookies, vous acceptez que le site</div>
<div>puisse les utiliser. Vous pouvez &agrave; tout moment d&eacute;sactiver ces cookies et ce</div>
<div>gratuitement &agrave; partir des possibilit&eacute;s de d&eacute;sactivation qui vous sont offertes</div>
<div>et rappel&eacute;es ci-apr&egrave;s, sachant que cela peut r&eacute;duire ou emp&ecirc;cher</div>
<div>l&rsquo;accessibilit&eacute; &agrave; tout ou partie des Services propos&eacute;s par le site.</div></div>
<div>&nbsp;</div>
<div><strong>9.1. &laquo; COOKIES &raquo;</strong></div>
<div class="container__legal">
<div>Un &laquo; cookie &raquo; est un petit fichier d&rsquo;information envoy&eacute; sur le navigateur de</div>
<div>l&rsquo;Utilisateur et enregistr&eacute; au sein du terminal de l&rsquo;Utilisateur (ex : ordinateur,</div>
<div>smartphone), (ci-apr&egrave;s &laquo; Cookies &raquo;). Ce fichier comprend des informations</div>
<div>telles que le nom de domaine de l&rsquo;Utilisateur, le fournisseur d&rsquo;acc&egrave;s Internet</div>
<div>de l&rsquo;Utilisateur, le syst&egrave;me d&rsquo;exploitation de l&rsquo;Utilisateur, ainsi que la date et</div>
<div>l&rsquo;heure d&rsquo;acc&egrave;s. Les Cookies ne risquent en aucun cas d&rsquo;endommager le</div>
<div>terminal de l&rsquo;Utilisateur. lacombe-peinture.fr est susceptible de traiter les informations de l&rsquo;Utilisateur concernant sa visite du Site, telles que les pages consult&eacute;es, les recherches effectu&eacute;es. Ces informations permettent &agrave;</div>
<div>lacombe-peinture.fr d&rsquo;am&eacute;liorer le contenu du Site, de la navigation de l&rsquo;Utilisateur.</div></div>
<div>&nbsp;</div>
<div>Les Cookies facilitant la navigation et/ou la fourniture des services propos&eacute;s</div>
</div>
</section>
<section class="pdf_page" aria-label="Page 10">
<div class="textlayer">
    <div class="container__legal">
<div>par le Site, l&rsquo;Utilisateur peut configurer son navigateur pour qu&rsquo;il lui permette</div>
<div>de d&eacute;cider s&rsquo;il souhaite ou non les accepter de mani&egrave;re &agrave; ce que des</div>
<div>Cookies soient enregistr&eacute;s dans le terminal ou, au contraire, qu&rsquo;ils soient</div>
<div>rejet&eacute;s, soit syst&eacute;matiquement, soit selon leur &eacute;metteur. L&rsquo;Utilisateur peut</div>
<div>&eacute;galement configurer son logiciel de navigation de mani&egrave;re &agrave; ce que</div>
<div>l&rsquo;acceptation ou le refus des Cookies lui soient propos&eacute;s ponctuellement,</div>
<div>avant qu&rsquo;un Cookie soit susceptible d&rsquo;&ecirc;tre enregistr&eacute; dans son terminal.</div>
<div>lacombe-peinture.frinforme l&rsquo;Utilisateur que, dans ce cas, il se peut que les</div>
<div>fonctionnalit&eacute;s de son logiciel de navigation ne soient pas toutes</div>
<div>disponibles.</div>
<div>Si l&rsquo;Utilisateur refuse l&rsquo;enregistrement de Cookies dans son terminal ou son</div>
<div>navigateur, ou si l&rsquo;Utilisateur supprime ceux qui y sont enregistr&eacute;s,</div>
<div>l&rsquo;Utilisateur est inform&eacute; que sa navigation et son exp&eacute;rience sur le Site</div>
<div>peuvent &ecirc;tre limit&eacute;es. Cela pourrait &eacute;galement &ecirc;tre le cas lorsque lacombe-peinture.fr ou l&rsquo;un de ses prestataires ne peut pas reconna&icirc;tre, &agrave; des fins de</div>
<div>compatibilit&eacute; technique, le type de navigateur utilis&eacute; par le terminal, les</div>
<div>param&egrave;tres de langue et d&rsquo;affichage ou le pays depuis lequel le terminal</div>
<div>semble connect&eacute; &agrave; Internet.</div>
<div>Le cas &eacute;ch&eacute;ant, lacombe-peinture.fr d&eacute;cline toute responsabilit&eacute; pour les</div>
<div>cons&eacute;quences li&eacute;es au fonctionnement d&eacute;grad&eacute; du Site et des services</div>
<div>&eacute;ventuellement propos&eacute;s par lacombe-peinture.fr, r&eacute;sultant (i) du refus de</div>
<div>Cookies par l&rsquo;Utilisateur (ii) de l&rsquo;impossibilit&eacute; pour lacombe-peinture.fr</div>
<div>d&rsquo;enregistrer ou de consulter les Cookies n&eacute;cessaires &agrave; leur fonctionnement</div>
<div>du fait du choix de l&rsquo;Utilisateur. Pour la gestion des Cookies et des choix de</div>
<div>l&rsquo;Utilisateur, la configuration de chaque navigateur est diff&eacute;rente. Elle est</div>
<div>d&eacute;crite dans le menu d&rsquo;aide du navigateur, qui permettra de savoir de quelle</div>
<div>mani&egrave;re l&rsquo;Utilisateur peut modifier ses souhaits en mati&egrave;re de Cookies.</div>
<div>&nbsp;</div>
<div>&Agrave; tout moment, l&rsquo;Utilisateur peut faire le choix d&rsquo;exprimer et de modifier ses</div>
<div>souhaits en mati&egrave;re de Cookies. lacombe-peinture.fr pourra en outre faire</div>
<div>appel aux services de prestataires externes pour l&rsquo;aider &agrave; recueillir et traiter</div>
<div>les informations d&eacute;crites dans cette section.</div>
<div>Enfin, en cliquant sur les ic&ocirc;nes d&eacute;di&eacute;es aux r&eacute;seaux sociaux Twitter,</div>
<div>Facebook, Linkedin et Google Plus figurant sur le Site de lacombe-peinture.fr</div></div>
</div>
</section>
<section class="pdf_page" aria-label="Page 11">
<div class="textlayer">
    <div class="container__legal">
<div>ou dans son application mobile et si l&rsquo;Utilisateur a accept&eacute; le</div>
<div>d&eacute;p&ocirc;t de cookies en poursuivant sa navigation sur le Site Internet ou</div>
<div>l&rsquo;application mobile de lacombe-peinture.fr, Twitter, Facebook, Linkedin et</div>
<div>Google Plus peuvent &eacute;galement d&eacute;poser des cookies sur vos terminaux</div>
<div>(ordinateur, tablette, t&eacute;l&eacute;phone portable).</div>
<div>Ces types de cookies ne sont d&eacute;pos&eacute;s sur vos terminaux qu&rsquo;&agrave; condition que</div>
<div>vous y consentiez, en continuant votre navigation sur le Site Internet ou</div>
<div>l&rsquo;application mobile de lacombe-peinture.fr. &Agrave; tout moment, l&rsquo;Utilisateur peut</div>
<div>n&eacute;anmoins revenir sur son consentement &agrave; ce que</div>
<div>lacombe-peinture.fr d&eacute;pose ce type de cookies.</div></div>
<div>&nbsp;</div>
<div><strong>Article 9.2. BALISES (&ldquo;TAGS&rdquo;) INTERNET</strong></div>
<div class="container__legal">
<div>Lacombe-peinture.fr peut employer occasionnellement des balises Internet</div>
<div>(&eacute;galement appel&eacute;es &laquo; tags &raquo;, ou balises d&rsquo;action, GIF &agrave; un pixel, GIF</div>
<div>transparents, GIF invisibles et GIF un &agrave; un) et les d&eacute;ployer par l&rsquo;interm&eacute;diaire</div>
<div>d&rsquo;un partenaire sp&eacute;cialiste d&rsquo;analyses Web susceptible de se trouver (et</div>
<div>donc de stocker les informations correspondantes, y compris l&rsquo;adresse IP de</div>
<div>l&rsquo;Utilisateur) dans un pays &eacute;tranger.</div>
<div>Ces balises sont plac&eacute;es &agrave; la fois dans les publicit&eacute;s en ligne permettant aux</div>
<div>internautes d&rsquo;acc&eacute;der au Site, et sur les diff&eacute;rentes pages de celui-ci.</div>
<div>Cette technologie permet &agrave; lacombe-peinture.fr d&rsquo;&eacute;valuer les r&eacute;ponses des</div>
<div>visiteurs face au Site et l&rsquo;efficacit&eacute; de ses actions (par exemple, le nombre de</div>
<div>fois o&ugrave; une page est ouverte et les informations consult&eacute;es), ainsi que</div>
<div>l&rsquo;utilisation de ce Site par l&rsquo;Utilisateur.</div>
<div>Le prestataire externe pourra &eacute;ventuellement recueillir des informations sur</div>
<div>les visiteurs du Site et d&rsquo;autres sites Internet gr&acirc;ce &agrave; ces balises, constituer</div>
<div>des rapports sur l&rsquo;activit&eacute; du Site &agrave; l&rsquo;attention de lacombe-peinture.fr, et</div>
<div>fournir d&rsquo;autres services relatifs &agrave; l&rsquo;utilisation de celui-ci et d&rsquo;Internet.</div></div>
<div>&nbsp;</div>
<div><strong>10. Droit applicable et attribution de juridiction.</strong></div>
<div>&nbsp;</div>
<div>Tout litige en relation avec l&rsquo;utilisation du site</div>
<div>lacombe-peinture.fr est soumis au droit fran&ccedil;ais. En dehors des cas o&ugrave; la loi ne le permet pas, il est fait attribution exclusive de juridiction aux tribunaux comp&eacute;tents d&rsquo;Albi.</div>
</div>
</section>
</div>
</div>
</section>
    </p>
</div>
</div>

</template>

<script>
export default {
     metaInfo() {
            return {
                title: 'Lacombe - Mentions légales', 
                meta: [
                    { charset: 'utf-8' },
                    { name: 'robots', content: 'noindex' },
                ]
            }
        },
}
</script>

<style lang="scss">

.banner-image {
        width: 100%;
        height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        padding: 0px 12px;
        box-sizing: border-box;
        position: relative;
        z-index: 2;

        h1 {
            margin: 0;
            font-size: 24px;
            z-index: 5;
        }
        h2 {
            margin: 0;
            margin-top: 8px;
            font-size: 20px;
            font-weight: 200;
            z-index: 5;
            color: #E9E9E9;
        }
    }

.banner__overlay {
    width: 100%;
    height: 100%;       
    background-color: #3A633D;
    opacity: 1;
    position: absolute;
    z-index: 3;
  }

.wrapper-legals {
    width: 100%;
    height: auto;
    padding: 60px 16px;
    box-sizing: border-box;
}
    .mentions-legales {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

.container__legal {
    display: flex; 
    flex-wrap: wrap;
}
</style>