<template>
<div>
    <div class="banner-image">
        <div class="banner__overlay"></div>
        <h1>Contact</h1>
        <h2>Une question, un devis ? Contactez-nous !</h2>
    </div>

    <div class="main-wrapper">
        <h3 class="main-wrapper__title">Établissement de Gaillac</h3>

        <div class="establishment-container">
            <div class="establishment-container__left" v-scroll-reveal.nomobile="{ delay: 200 }" >
                <div><span>Adresse</span> : 3 Avenue Georges Clémenceau, 81000 Gaillac</div>
                <div><span>Téléphone</span> : 05 63 57 12 22</div>
                <div><span>Fax</span> : 05 63 57 50 70</div>
                <div><span>Email</span> : ets.gaillac@lacombe-peinture.fr</div>
            </div>
            <div class="establishment-container__right" v-scroll-reveal.nomobile="{ delay: 220 }" >
                <div class="establishment-container__right--inner">
                    <span>Horaire :</span> <div class="schedule-inner"><div class="indic">lundi-jeudi / 9h-12h, 14h-18h30</div><div>vendredi / 9h-12h</div></div>
                </div>
                <img class="LacombeV" alt="logo Lacombe" src="../assets/LacombeV.jpg"/>
            </div>
        </div>

        <div class="separator"></div>

        <h3 class="main-wrapper__title">Établissement de Castres</h3>
        <div class="establishment-container">
            <div class="establishment-container__left" v-scroll-reveal.nomobile="{ delay: 200 }" >
                <div><span>Adresse</span> : 34 rue Fermat, 81100 Castres</div>
                <div><span>Téléphone</span> : 05 63 35 96 48</div>
                <div><span>Fax</span> : 05 63 74 38 90</div>
                <div><span>Email</span> : ets.castres@lacombe-peinture.fr</div>
            </div>
            <div class="establishment-container__right" v-scroll-reveal.nomobile="{ delay: 220 }" >
                <div class="establishment-container__right--inner">
                    <span>Horaire :</span> <div class="schedule-inner"><div class="indic">lundi-jeudi / 9h-12h, 14h-18h30</div><div>vendredi / 9h-12h</div></div>
                </div>
                <img class="LacombeM" alt="logo Lacombe" src="../assets/LacombeM.jpg"/>
            </div>
        </div>

        <div class="separator"></div>

        <img src="../assets/map-contact.jpg" alt="Map Localisation" class="mapContact"/>
    </div>


    <div class="contact-wrapper" v-scroll-reveal.nomobile="{ delay: 150 }" >
        <div class="contact-wrapper__container">
            <div class="contact-wrapper__title">Formulaire de contact</div>
            <form action="https://formspree.io/ets.gaillac@lacombe-peinture.fr" method="POST" class="contact-wrapper__inner">
                <div class="contact-container__top">
                    <div class="contact-container__left">
                         <div class="contact-wrapper__subtitle">Nom</div>
                         <input type="text" name="name" class="input-field name" autocomplete="off"  />

                         <div class="contact-wrapper__subtitle">Téléphone</div>
                         <input type="number"  name="phone" class="input-field phone" autocomplete="off" />

                         <div class="contact-wrapper__subtitle">Mail</div>
                         <input name="email" type="text" class="input-field email" autocomplete="off" />
                    </div>
                    <div class="contact-container__right">
                        <div class="contact-wrapper__subtitle">Message</div>
                         <textarea name="message" rows="30" cols="33" type="text" class="text-field message" ></textarea>
                    </div>
                   

                </div>
                <div class="contact-container__bottom">
                    <div class="contact-container__left--bottom">
                         <input type="checkbox" id="checkbox" />
                         <label for="checkbox"><p>En soumettant ce formulaire, j'accepte que les informations rentrées soient exploitées par SARL Lacombe</p></label>
                    </div>
                    <div class="contact-container__right">
                        <input class="button contact-submit" type="submit" value="Envoyer">
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>

</template>

<script>
export default {
    metaInfo() {
            return {
                title: 'Lacombe - Contact', 
                meta: [
                    { charset: 'utf-8' },
                    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                    { vmid: 'description', name: 'description', content: "Contactez-nous pour établir votre devis personnalisé"},
                ]
            }
        },
}
</script>

<style lang="scss" scoped>

$wrapperSize: 1200px;

.banner-image {
        width: 100%;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        padding: 0px 12px;
        box-sizing: border-box;
        position: relative;
        background-image: url('../assets/maisonAsso.jpg');
        background-size: cover;
        z-index: 2;

        h1 {
            margin: 0;
            font-size: 38px;
            z-index: 5;
        }
        h2 {
            margin: 0;
            margin-top: 8px;
            font-size: 22px;
            font-weight: 200;
            z-index: 5;
            color: #E9E9E9;
        }
    }

.banner__overlay {
    width: 100%;
    height: 100%;       
    background-color: #674488;
    opacity: 0.9;
    position: absolute;
    z-index: 3;
  }

  .main-wrapper {
      width: $wrapperSize;
      margin-left: auto;
      margin-right: auto;
      padding: 45px 12px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      
      h3 {
          font-size: 18px;
          font-weight: 500;
          margin: 40px 0px;
          margin-top: 20px;
      }

      p {
          margin-bottom: 28px;
      }
  }

  .main-wrapper .main-wrapper__title {
    font-size: 32px;
    color: #674488;
    margin-top: 40px;
    margin-bottom: 50px;
    width: 50%;
    font-weight: 600;
  }

  .establishment-container {
      width: 100%;
      display: flex;
      justify-content:  space-between;
  }

  .establishment-container__left {
       box-sizing: border-box;
      div {
          font-size: 21px;
          margin-bottom: 12px;
      }
      span {
          font-weight: 700;
      }
  }

  .establishment-container__right{
      display: flex;
      flex-direction: column;
      font-size: 21px;
       box-sizing: border-box;
      span {
          font-weight: 700;
      }

  }

  .establishment-container__right--inner {
      display: flex;
      box-sizing: border-box;

      span {
          margin-right: 10px;
      }
  }

  .schedule-inner {
      display: flex;
      flex-direction: column;
  }

  .separator {
      border-bottom: 1px solid #DEDEDE;
      width: 80px;
      margin: 20px 0px;
      margin-top: 30px;
  }

  .mapContact {
      width: 800px;
      height: auto;
      max-height: 450px;
      margin: 40px 0px;
      margin-left: auto;
      margin-right: auto;
  }

  .LacombeV, .LacombeM {
      width: 170px; 
      height: 90px; 
      align-self: flex-end;
      margin-top: 20px;
  }

  .contact-wrapper {
      width: 100%;
      background-color: #674488;
      display: flex;
      padding: 50px 12px;
      box-sizing: border-box;
      margin-bottom: 48px;
  }

  .contact-wrapper__container {
      width: 1000px;
      margin-right: auto;
      margin-left:auto;
       box-sizing: border-box;
  }

  .contact-wrapper__title {
      color: white;
      font-size: 23px;
      font-weight: 500;
      margin-bottom: 24px;
  }

  .contact-wrapper__subtitle {
      color: white;
      margin-bottom: 5px;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;

  }

  .input-field {
      background-color: #C3B4CF;
      border: 1px solid white;
      padding: 6px 12px;
      width: 360px;
      border-radius: 4px;
      margin-bottom: 24px;
      height: 28px;
      font-size: 20px;
      outline: none;
      color: white;
  }

  .text-field {
      background-color: #C3B4CF;
      border: 1px solid white;
      padding: 6px 12px;
      width: 360px;
      border-radius: 4px;
      margin-bottom: 24px;
      height: 205px;
      font-size: 20px;
      outline: none;
      color: white;
  }

  .contact-container__top {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
  }

  .contact-container__bottom {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
  }

  .contact-container__left--bottom {
      display: flex;
      box-sizing: border-box;
      width: 40%;

      p {
          margin: 0;
          margin-left: 8px;
          color: white;
          font-size: 13px;
          font-weight: 200;
          cursor: pointer;
      }
      #checkbox {
          cursor: pointer;
      }
  }

  .contact-submit {
      background-color: white;
      color: #674488;
      text-transform: uppercase;
      font-size: 16px;
      padding: 6px 24px;
      font-weight: 500;
      border-radius: 4Px;
      border: none;
      cursor: pointer;
  }

  textarea {
      resize: none;
  }







  @media(max-width: 1300px) {
      .main-wrapper {
      width: 1020px;
      margin-left: auto;
      margin-right: auto;
      padding: 45px 12px;
      display: flex;
      flex-direction: column;
      
      h3 {
          font-size: 16px;
          font-weight: 500;
          margin: 40px 0px;
          margin-top: 20px;
      }

      p {
          margin-bottom: 28px;
      }
  }

  .main-wrapper .main-wrapper__title {
    font-size: 28px;
    color: #674488;
    margin-top: 40px;
    margin-bottom: 50px;
    width: 100%;
  }

  .establishment-container__left {
      
      div {
          width: 100%;
          font-size: 18px;
          margin-bottom: 12px;
          box-sizing: border-box;
      }
      span {
          font-weight: 600;
      }
  }

  .establishment-container__right{
      display: flex;
      flex-direction: column;
      font-size: 18px;
      span {
          font-weight: 600;
      }
  }
  }

  @media(max-width: 850px) {
      .main-wrapper {
          width: 100%;
      }

      .establishment-container {
      width: 100%;
      display: flex;
      justify-content:  center;
      flex-direction: column;
      box-sizing: border-box;
  }

  .establishment-container__right{
      display: flex;
      font-size: 18px;
      span {
          font-weight: 600;
      }

      .placeholder {
          margin-top: 30px;
          align-self: flex-start;
      }
  }

  .establishment-container__right--inner {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;

      span {
          margin-right: 10px;
      }
  }

  .schedule-inner {
      display: flex;
      flex-direction: row;
       box-sizing: border-box;
  }

  .indic {
      margin-right: 10px;
  }

  .contact-wrapper__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
  }

  .contact-wrapper__container {
      width: 100%;
  }

  .contact-container__top {
      display: flex;
      flex-direction: column;
  }

  .contact-container__bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
  }

  .contact-container__right {
      display: flex;
      flex-direction: column;
  }

  .contact-submit {
      align-self: flex-start;
  }

  .contact-wrapepr__inner {
      justify-content: center;
      align-items: center;
  }

  .contact-container__left--bottom {
      width: 100%;
      margin: 20px;
  }
  }

  @media (min-width: 1300px) {
      .input-field {
          width: 450px;
          height: 40px;
      }

      .text-field {
          width: 400px;
          height: 240px;
      }
  }

  @media (max-width: 600px) {
      .banner-image {
          text-align: center;
        h1 {
            margin: 0;
            font-size: 26px;
            z-index: 5;
        }
        h2 {
            margin: 0;
            margin-top: 8px;
            font-size: 14px;
            font-weight: 200;
            z-index: 5;
        }
    }

    .input-field {
          width: 100%;
          box-sizing: border-box;
          height: 45px;
      }

      .text-field {
          width: 100%;
          box-sizing: border-box;
      }

      .contact-wrapper {
          margin-top: -40px;
      }

    .mapContact {
      width: 100%;
      height: auto;
      max-height: 450px;
      margin: 40px 0px;
      margin-left: auto;
      margin-right: auto;
  }
  }

</style>