<template>
    <div>
        <div class="banner-image">
            <div class="banner__overlay"></div>
            <h1>L'entreprise Lacombe</h1>
            <h2>Notre entreprise, nos valeurs ...</h2>
        </div>

        <div class="main-wrapper" v-scroll-reveal.nomobile="{ delay: 100 }" >
            <h2 class="main-wrapper__title">Notre entreprise</h2>

            <h3><strong>Depuis plus de 30 ans, la SARL LACOMBE, gérée par M. LACOMBE, a su s'imposer dans le tarn.</strong></h3>

            <p>Forte d'une véritable notoriété (plus de 30 ans d'expérience en marchés publics), un établissement secondaire est ouvert à Castres en 2012.</p>
            <p>La gestion administrative, comptable, technique et la réalisation des travaux sont assurés par un effectif de vingt-et-une personnes, dont cinq femmes et seize hommes. Nous faisons régulièrement appel à des agences d'intérim lesquelles nous confient du personnel de confiance et expérimenté.</p>
            <p>Avec un parc de 21 véhicules, plusieurs dépôts de stockage et tout le matériel nécessaire à la réalisation de tous types de chantiers, vous pouvez nous confier en toute tranquilité vos plus beaux projets !</p>

            <img src="../assets/map.jpg" alt="Map Localisation" class="map-localisation"/>

        <div class="line-separator"></div>
        <div class="small-indicator" v-scroll-reveal.nomobile="{ delay: 150 }"><strong>Nous intervenons sur les secteurs de L'Union, Balma, Labège, Launaguet, Castelginest, Gaillac, Albi, Carmaux, Castres et Mazamet.</strong></div>
        <div class="line-separatorv2"></div>

        </div>

        <div class="main-wrapper">
            <h2 class="main-wrapper__title" v-scroll-reveal.nomobile="{ delay: 200 }">Nos valeurs</h2>

            <div class="valor__container">

                <div class="valor__section" v-scroll-reveal.nomobile="{ delay: 200 }">
                    <h4>L'innovation organisationnelle</h4>
                    <p>Notre expérience nous a permis de développer un véritable mode opératoire d’organisation de chantiers. Il garantit un suivi, unetraçabilité des étapes du projet dans le respect des délais et des contraintes du client.</p>
                </div>

                <div class="valor__section" v-scroll-reveal.nomobile="{ delay: 200 }">
                    <h4>L'innovation technologique</h4>
                    <p>Notre défi quotidien est de proposer à nos clients les meilleures solutions pour le confort et le respect de l’environnement (produits NF ENVIRONNEMENT et EU ECOLABEL), recyclage des peintures, des encres et toner.</p>
                </div>

                <div class="valor__section" v-scroll-reveal.nomobile="{ delay: 200 }">
                    <h4>Notre engagement : la qualité de finition de nos prestations</h4>
                    <p>Depuis plus de 30 ans, nous avons développé un savoir-faire renforcé aujourd’hui par 15 compagnons expérimentés. La qualification, la proximité et la disponibilité de nos équipes seront pour vous les plus surs garants d’un service approprié.</p>
                </div>

                <div class="valor__section" v-scroll-reveal.nomobile="{ delay: 200 }">
                    <h4>Une chance pour tous</h4>
                    <p>Dans l’entreprise, tout le monde a ses chances ; travailleurs au statut d’handicapé, CDI, CDD, apprentis, travailleurs ayantdes restrictions médicales (en CDI).</p>
                </div>

                <div class="valor__section" v-scroll-reveal.nomobile="{ delay: 200 }">
                    <h4>Formations</h4>
                    <p>Notre entreprise contribue à la composante sociale du développement durable. C’est pour cette raison que nous investissons dans des formations spécifiques (en informatique, permis CACES, Amiante sous-section 4, sauveteur secouristes du travail.</p>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    metaInfo() {
            return {
                title: 'Lacombe - Entreprise', 
                meta: [
                    { charset: 'utf-8' },
                    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                    { vmid: 'description', name: 'description', content: 'Découvrez l\'entreprise Lacombe et ses valeurs'},
                ]
            }
        },
}

</script>

<style scoped lang="scss">

.banner-image {
        width: 100%;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        padding: 0px 12px;
        box-sizing: border-box;
        position: relative;
        background-image: url('../assets/maison3.jpg');
        background-size: cover;
        text-align: center;
        z-index: 2;

        h1 {
            margin: 0;
            font-size: 38px;
            font-weight: bold;
            z-index: 5;
        }
        h2 {
            margin: 0;
            margin-top: 8px;
            font-size: 20px;
            font-weight: 200;
            z-index: 5;
            color: #E6E6E6;
        }
    }

.banner__overlay {
    width: 100%;
    height: 100%;       
    background-color: #3A633D;
    opacity: 0.8;
    position: absolute;
    z-index: 3;
  }

  .main-wrapper {
      width: 1250px;
      margin-left: auto;
      margin-right: auto;
      padding: 45px 12px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      
      h3 {
          font-size: 24px;
          margin: 40px 0px;
          margin-top: 20px;

          strong {
            font-weight: 600;
          }
      }

      p {
          margin-bottom: 28px;
          font-size: 18px;
      }
  }

  .main-wrapper__title {
    text-transform: uppercase;
    font-size: 28px;
    color: #3A633D;
    margin-bottom: 45px;
    font-weight: bold;
  }

  .map-localisation {
      width: 800px;
      margin-left: auto;
      margin-right: auto;
      align-self: center;
      height: auto;
      margin: 40px 0px;
      z-index: 1;
      max-height: 450px;
  }

  .line-separator {
      border-bottom: 1px solid #DEDEDE;
      height: 1px;
      width: 90px;
      margin: 40px 0px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
  }

  .line-separatorv2 {
      border-bottom: 1px solid #DEDEDE;
      height: 1px;
      width: 90px;
      margin: 40px 0px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      margin-bottom: -40px;
  }

  .small-indicator {
      width: 600px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      font-size: 23px;
      line-height: 40px;
      font-weight: 500;
  }

  .valor__container {
      width: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
  }

  .valor__container {
      .valor__section {
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;
      border-radius: 25px;
      width: 100%;
      padding: 32px;
      color: white;
      box-sizing: border-box;

      h4 {
          margin: 0px;
          font-weight: 700;
          font-size: 22px;
      }

      p {
          line-height: 30px;
          font-size: 20px;
          margin-bottom: 0px;
          font-weight: 300;
      }
  }
  }

  .valor__container .valor__section:nth-child(1) {
      background-color: #4E74B7;
  }
  .valor__container .valor__section:nth-child(2) {
      background-color: #C3372C;
  }
  .valor__container .valor__section:nth-child(3) {
      background-color: #DE991B;
  }
  .valor__container .valor__section:nth-child(4) {
      background-color: #693F8C;
  }
  .valor__container .valor__section:nth-child(5) {
      background-color: #386539;
      margin-bottom: 34px;
  }

  @media(max-width: 1300px) {
    .main-wrapper {
      width: 1000px;
      margin-left: auto;
      margin-right: auto;
      padding: 45px 12px;
      box-sizing: border-box;
      
      h3 {
          font-size: 22px;
          margin: 40px 0px;
          margin-top: 20px;

          strong {
            font-weight: 600;
          }
      }

      p {
          margin-bottom: 28px;
          font-size: 18px;
      }
  }
  }

  @media(max-width: 930px) {
      .main-wrapper {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding: 45px 12px;
      box-sizing: border-box;
      
      h3 {
          font-size: 22px;
          margin: 40px 0px;
          margin-top: 20px;

          strong {
            font-weight: 600;
          }
      }

      p {
          margin-bottom: 28px;
          font-size: 18px;
      }
  }

  .small-indicator {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      font-size: 18px;
      line-height: 30px;
      font-weight: 500;
      padding: 0px 16px;
      box-sizing: border-box;
  }

  .valor__section {
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;
      border-radius: 25px;
      width: 100%;
      padding: 32px;
      color: white;
      box-sizing: border-box;

      h4 {
          margin: 0px;
          font-weight: 700;
          font-size: 20px;
      }

      p {
          line-height: 25px;
          font-size: 16px;
          margin-bottom: 0px;
          font-weight: 200;
      }
  }
  }

  @media(max-width: 600px) {
      .banner-image {
        h1 {
            margin: 0;
            font-size: 32px;
            z-index: 5;
        }
        h2 {
            margin: 0;
            margin-top: 8px;
            font-size: 18px;
            font-weight: 200;
            z-index: 5;
        }
    }

    .map-localisation {
      width: 100%;
      max-height: auto;
  }
  }


    
</style>