<template>
<div>
    <div class="banner-image">
        <div class="banner__overlay"></div>
        <h1>Nos prestations et fournisseurs</h1>
        <h2>Des prestations et des fournisseurs de qualité</h2>
    </div>

    <div class="main-wrapper">
        <h3 class="main-wrapper__title">Nos dispositions pour garantir une prestation de qualité</h3>


        <div class="section-one">
            <img class="section-one__image" src="../assets/pot.jpg" alt="pot" v-scroll-reveal.nomobile="{ delay: 150 }" />

            <div class="section-one__text" >
                <h3 v-scroll-reveal.nomobile="{ delay: 150 }"  class="section-one__subtitle">Avant</h3>
                <p v-scroll-reveal.nomobile="{ delay: 150 }" >Protection des meubles et des sols</p>
                <p v-scroll-reveal.nomobile="{ delay: 150 }" >L'application des peintures, vernis, enduits, et préparations assimilées ne devra être faite :</p>

                    <div v-scroll-reveal.nomobile="{ delay: 150 }"  class="section-one__text--item"><span>•</span><p>ni par température ambiante inférieure à + 5°C,</p></div>
                    <div v-scroll-reveal.nomobile="{ delay: 150 }"  class="section-one__text--item"><span>•</span><p>ni dans une atmosphère susceptible de donner lieu à des condensations,</p></div>
                    <div v-scroll-reveal.nomobile="{ delay: 150 }"  class="section-one__text--item"><span>•</span><p>ni sur des subjectiles gelés ou surchauffés,</p></div>
                    <div v-scroll-reveal.nomobile="{ delay: 150 }"  class="section-one__text--item"><span>•</span><p>ni dans une atmosphère poussiéreuse</p></div>

                <h3 class="section-one__subtitle" v-scroll-reveal.nomobile="{ delay: 150 }" >Pendant</h3>

                <p v-scroll-reveal.nomobile="{ delay: 150 }" >Lessivage des parois, ratissage, impression, couche de primaire et application de peinture</p>
            </div>
        </div>

        <h3 class="main-wrapper__title" v-scroll-reveal.nomobile="{ delay: 150 }" >Nos prestations</h3>

        <div class="main-wrapper__labelling" v-scroll-reveal.nomobile="{ delay: 150 }" >Travaux de peinture</div>
        <div class="section-two__item" v-scroll-reveal.nomobile="{ delay: 150 }" >
            <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>color-rolling-brush</title><path d="M23,2.5H21.75a.25.25,0,0,0-.25.25v1.5a.25.25,0,0,0,.25.25H23a1,1,0,0,0,0-2Z"/><path d="M3,2.5a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h8a1,1,0,0,1,1,1v.325a.25.25,0,0,0,.3.245,3.535,3.535,0,0,1,1.4,0,.25.25,0,0,0,.3-.245V11.5a3,3,0,0,0-3-3H3a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1h.25a.25.25,0,0,0,.25-.25V2.75a.25.25,0,0,0-.25-.25Z"/><rect x="4.5" width="16" height="7" rx="1.5" ry="1.5"/><path d="M10.5,15.5v7A1.5,1.5,0,0,0,12,24h2a1.5,1.5,0,0,0,1.5-1.5v-7a2.5,2.5,0,0,0-5,0Z"/></svg>
            <p><strong>Bâtiments neufs ou en rénovation</strong></p>
        </div>
        <div class="section-two__item" v-scroll-reveal.nomobile="{ delay: 150 }" >
             <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>color-rolling-brush</title><path d="M23,2.5H21.75a.25.25,0,0,0-.25.25v1.5a.25.25,0,0,0,.25.25H23a1,1,0,0,0,0-2Z"/><path d="M3,2.5a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h8a1,1,0,0,1,1,1v.325a.25.25,0,0,0,.3.245,3.535,3.535,0,0,1,1.4,0,.25.25,0,0,0,.3-.245V11.5a3,3,0,0,0-3-3H3a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1h.25a.25.25,0,0,0,.25-.25V2.75a.25.25,0,0,0-.25-.25Z"/><rect x="4.5" width="16" height="7" rx="1.5" ry="1.5"/><path d="M10.5,15.5v7A1.5,1.5,0,0,0,12,24h2a1.5,1.5,0,0,0,1.5-1.5v-7a2.5,2.5,0,0,0-5,0Z"/></svg>
            <p><strong>Travaux de peinture en intétieur ou en extérieur</strong></p>
        </div>
        <div class="section-two__item" v-scroll-reveal.nomobile="{ delay: 150 }" >
             <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>color-rolling-brush</title><path d="M23,2.5H21.75a.25.25,0,0,0-.25.25v1.5a.25.25,0,0,0,.25.25H23a1,1,0,0,0,0-2Z"/><path d="M3,2.5a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h8a1,1,0,0,1,1,1v.325a.25.25,0,0,0,.3.245,3.535,3.535,0,0,1,1.4,0,.25.25,0,0,0,.3-.245V11.5a3,3,0,0,0-3-3H3a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1h.25a.25.25,0,0,0,.25-.25V2.75a.25.25,0,0,0-.25-.25Z"/><rect x="4.5" width="16" height="7" rx="1.5" ry="1.5"/><path d="M10.5,15.5v7A1.5,1.5,0,0,0,12,24h2a1.5,1.5,0,0,0,1.5-1.5v-7a2.5,2.5,0,0,0-5,0Z"/></svg>
            <p><strong>Effet mats, satinés ou brillants</strong></p>
        </div>
        <div class="section-two__item" v-scroll-reveal.nomobile="{ delay: 150 }" >
             <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>color-rolling-brush</title><path d="M23,2.5H21.75a.25.25,0,0,0-.25.25v1.5a.25.25,0,0,0,.25.25H23a1,1,0,0,0,0-2Z"/><path d="M3,2.5a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h8a1,1,0,0,1,1,1v.325a.25.25,0,0,0,.3.245,3.535,3.535,0,0,1,1.4,0,.25.25,0,0,0,.3-.245V11.5a3,3,0,0,0-3-3H3a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1h.25a.25.25,0,0,0,.25-.25V2.75a.25.25,0,0,0-.25-.25Z"/><rect x="4.5" width="16" height="7" rx="1.5" ry="1.5"/><path d="M10.5,15.5v7A1.5,1.5,0,0,0,12,24h2a1.5,1.5,0,0,0,1.5-1.5v-7a2.5,2.5,0,0,0-5,0Z"/></svg>
            <p><strong>Logements, locaux commerciaux, restaurants, institutions publiques</strong> (mairies, écoles, salles des fêtes, hôpitaux, maisons de retraire, centres médicaux ...)</p>
        </div>
        <div class="section-two__item" v-scroll-reveal.nomobile="{ delay: 150 }" >
             <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>color-rolling-brush</title><path d="M23,2.5H21.75a.25.25,0,0,0-.25.25v1.5a.25.25,0,0,0,.25.25H23a1,1,0,0,0,0-2Z"/><path d="M3,2.5a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h8a1,1,0,0,1,1,1v.325a.25.25,0,0,0,.3.245,3.535,3.535,0,0,1,1.4,0,.25.25,0,0,0,.3-.245V11.5a3,3,0,0,0-3-3H3a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1h.25a.25.25,0,0,0,.25-.25V2.75a.25.25,0,0,0-.25-.25Z"/><rect x="4.5" width="16" height="7" rx="1.5" ry="1.5"/><path d="M10.5,15.5v7A1.5,1.5,0,0,0,12,24h2a1.5,1.5,0,0,0,1.5-1.5v-7a2.5,2.5,0,0,0-5,0Z"/></svg>
            <p><strong>Tous supports</strong> (Murs en plaques de plâtre, béton ou Fermacell, plafonds en plaques de plâtre, en béton ou perforés, toutes boiseries et serrureries (portes, fenêtres, volets, avant-toits, charpente, garde-corps, escaliers, portails et portillons...))</p>
        </div>
        <div class="section-two__item" v-scroll-reveal.nomobile="{ delay: 150 }" >
             <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>color-rolling-brush</title><path d="M23,2.5H21.75a.25.25,0,0,0-.25.25v1.5a.25.25,0,0,0,.25.25H23a1,1,0,0,0,0-2Z"/><path d="M3,2.5a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h8a1,1,0,0,1,1,1v.325a.25.25,0,0,0,.3.245,3.535,3.535,0,0,1,1.4,0,.25.25,0,0,0,.3-.245V11.5a3,3,0,0,0-3-3H3a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1h.25a.25.25,0,0,0,.25-.25V2.75a.25.25,0,0,0-.25-.25Z"/><rect x="4.5" width="16" height="7" rx="1.5" ry="1.5"/><path d="M10.5,15.5v7A1.5,1.5,0,0,0,12,24h2a1.5,1.5,0,0,0,1.5-1.5v-7a2.5,2.5,0,0,0-5,0Z"/></svg>
            <p><strong>Peintures spéciales</strong> (à la chaux, enduits décoratifs (argenté, cuivré ou nacré), antibactériennes, captant les odeurs, cire, enduit coloré imperméable ...)</p>
        </div>
        <div class="section-two__item" v-scroll-reveal.nomobile="{ delay: 150 }" >
             <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>color-rolling-brush</title><path d="M23,2.5H21.75a.25.25,0,0,0-.25.25v1.5a.25.25,0,0,0,.25.25H23a1,1,0,0,0,0-2Z"/><path d="M3,2.5a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h8a1,1,0,0,1,1,1v.325a.25.25,0,0,0,.3.245,3.535,3.535,0,0,1,1.4,0,.25.25,0,0,0,.3-.245V11.5a3,3,0,0,0-3-3H3a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1h.25a.25.25,0,0,0,.25-.25V2.75a.25.25,0,0,0-.25-.25Z"/><rect x="4.5" width="16" height="7" rx="1.5" ry="1.5"/><path d="M10.5,15.5v7A1.5,1.5,0,0,0,12,24h2a1.5,1.5,0,0,0,1.5-1.5v-7a2.5,2.5,0,0,0-5,0Z"/></svg>
            <p><strong>Papiers peints, toile tissée, toile de verre</strong></p>
        </div>
        <div class="section-two__item" v-scroll-reveal.nomobile="{ delay: 150 }" >
             <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>color-rolling-brush</title><path d="M23,2.5H21.75a.25.25,0,0,0-.25.25v1.5a.25.25,0,0,0,.25.25H23a1,1,0,0,0,0-2Z"/><path d="M3,2.5a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h8a1,1,0,0,1,1,1v.325a.25.25,0,0,0,.3.245,3.535,3.535,0,0,1,1.4,0,.25.25,0,0,0,.3-.245V11.5a3,3,0,0,0-3-3H3a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1h.25a.25.25,0,0,0,.25-.25V2.75a.25.25,0,0,0-.25-.25Z"/><rect x="4.5" width="16" height="7" rx="1.5" ry="1.5"/><path d="M10.5,15.5v7A1.5,1.5,0,0,0,12,24h2a1.5,1.5,0,0,0,1.5-1.5v-7a2.5,2.5,0,0,0-5,0Z"/></svg>
            <p><strong>Stuc</strong>, finition ferrée (Enduit fin à la chaux dont les effets imitent le marbre. Le stuc ferré se passe en deux couches fines successives. La deuxième couche ferrée pour obtenir une surface très lisse apparentée à du marbre. Le passage répété de la spatule à ferrer sur l'enduit multiplie les tons, les traces, la brillance. L'enduit peut donc être plus ou moins moiré (reflets changeants et chatoyants). Une finition à la cire donne des couleurs profondes aux reflets brillants.)</p>
        </div>
        <div class="section-two__item" v-scroll-reveal.nomobile="{ delay: 150 }" >
             <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>color-rolling-brush</title><path d="M23,2.5H21.75a.25.25,0,0,0-.25.25v1.5a.25.25,0,0,0,.25.25H23a1,1,0,0,0,0-2Z"/><path d="M3,2.5a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h8a1,1,0,0,1,1,1v.325a.25.25,0,0,0,.3.245,3.535,3.535,0,0,1,1.4,0,.25.25,0,0,0,.3-.245V11.5a3,3,0,0,0-3-3H3a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1h.25a.25.25,0,0,0,.25-.25V2.75a.25.25,0,0,0-.25-.25Z"/><rect x="4.5" width="16" height="7" rx="1.5" ry="1.5"/><path d="M10.5,15.5v7A1.5,1.5,0,0,0,12,24h2a1.5,1.5,0,0,0,1.5-1.5v-7a2.5,2.5,0,0,0-5,0Z"/></svg>
            <p><strong>Tadelakt</strong> (Enduit coloré imperméable, fait de chaux polie avec un galet de rivière et traitée au savon noir, recouvrant traditionnellement les murs des habitations dans le Sud marocain.)</p>
        </div>
        <div class="section-two__item" v-scroll-reveal.nomobile="{ delay: 150 }" >
             <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>color-rolling-brush</title><path d="M23,2.5H21.75a.25.25,0,0,0-.25.25v1.5a.25.25,0,0,0,.25.25H23a1,1,0,0,0,0-2Z"/><path d="M3,2.5a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h8a1,1,0,0,1,1,1v.325a.25.25,0,0,0,.3.245,3.535,3.535,0,0,1,1.4,0,.25.25,0,0,0,.3-.245V11.5a3,3,0,0,0-3-3H3a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1h.25a.25.25,0,0,0,.25-.25V2.75a.25.25,0,0,0-.25-.25Z"/><rect x="4.5" width="16" height="7" rx="1.5" ry="1.5"/><path d="M10.5,15.5v7A1.5,1.5,0,0,0,12,24h2a1.5,1.5,0,0,0,1.5-1.5v-7a2.5,2.5,0,0,0-5,0Z"/></svg>
            <p><strong>Caréniat</strong> (Enduit décoratif mat, allié à de subtils effets décoratifs argenté, cuivré ou nacré.)</p>
        </div>

        <div class="main-wrapper__labelling" v-scroll-reveal.nomobile="{ delay: 150 }" >Ravalement de façades</div>

        <div class="section-two__item" v-scroll-reveal.nomobile="{ delay: 150 }" >
             <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>color-rolling-brush</title><path d="M23,2.5H21.75a.25.25,0,0,0-.25.25v1.5a.25.25,0,0,0,.25.25H23a1,1,0,0,0,0-2Z"/><path d="M3,2.5a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h8a1,1,0,0,1,1,1v.325a.25.25,0,0,0,.3.245,3.535,3.535,0,0,1,1.4,0,.25.25,0,0,0,.3-.245V11.5a3,3,0,0,0-3-3H3a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1h.25a.25.25,0,0,0,.25-.25V2.75a.25.25,0,0,0-.25-.25Z"/><rect x="4.5" width="16" height="7" rx="1.5" ry="1.5"/><path d="M10.5,15.5v7A1.5,1.5,0,0,0,12,24h2a1.5,1.5,0,0,0,1.5-1.5v-7a2.5,2.5,0,0,0-5,0Z"/></svg>
            <p><strong>Décapage</strong></p>
        </div>

        <div class="section-two__item" v-scroll-reveal.nomobile="{ delay: 150 }" >
             <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>color-rolling-brush</title><path d="M23,2.5H21.75a.25.25,0,0,0-.25.25v1.5a.25.25,0,0,0,.25.25H23a1,1,0,0,0,0-2Z"/><path d="M3,2.5a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h8a1,1,0,0,1,1,1v.325a.25.25,0,0,0,.3.245,3.535,3.535,0,0,1,1.4,0,.25.25,0,0,0,.3-.245V11.5a3,3,0,0,0-3-3H3a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1h.25a.25.25,0,0,0,.25-.25V2.75a.25.25,0,0,0-.25-.25Z"/><rect x="4.5" width="16" height="7" rx="1.5" ry="1.5"/><path d="M10.5,15.5v7A1.5,1.5,0,0,0,12,24h2a1.5,1.5,0,0,0,1.5-1.5v-7a2.5,2.5,0,0,0-5,0Z"/></svg>
            <p><strong>Lavage à haute pression</strong></p>
        </div>

        <div class="section-two__item" v-scroll-reveal.nomobile="{ delay: 150 }" >
             <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>color-rolling-brush</title><path d="M23,2.5H21.75a.25.25,0,0,0-.25.25v1.5a.25.25,0,0,0,.25.25H23a1,1,0,0,0,0-2Z"/><path d="M3,2.5a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h8a1,1,0,0,1,1,1v.325a.25.25,0,0,0,.3.245,3.535,3.535,0,0,1,1.4,0,.25.25,0,0,0,.3-.245V11.5a3,3,0,0,0-3-3H3a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1h.25a.25.25,0,0,0,.25-.25V2.75a.25.25,0,0,0-.25-.25Z"/><rect x="4.5" width="16" height="7" rx="1.5" ry="1.5"/><path d="M10.5,15.5v7A1.5,1.5,0,0,0,12,24h2a1.5,1.5,0,0,0,1.5-1.5v-7a2.5,2.5,0,0,0-5,0Z"/></svg>
            <p><strong>Mise en peinture</strong></p>
        </div>

        <div class="section-two__item" v-scroll-reveal.nomobile="{ delay: 150 }" >
             <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>color-rolling-brush</title><path d="M23,2.5H21.75a.25.25,0,0,0-.25.25v1.5a.25.25,0,0,0,.25.25H23a1,1,0,0,0,0-2Z"/><path d="M3,2.5a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h8a1,1,0,0,1,1,1v.325a.25.25,0,0,0,.3.245,3.535,3.535,0,0,1,1.4,0,.25.25,0,0,0,.3-.245V11.5a3,3,0,0,0-3-3H3a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1h.25a.25.25,0,0,0,.25-.25V2.75a.25.25,0,0,0-.25-.25Z"/><rect x="4.5" width="16" height="7" rx="1.5" ry="1.5"/><path d="M10.5,15.5v7A1.5,1.5,0,0,0,12,24h2a1.5,1.5,0,0,0,1.5-1.5v-7a2.5,2.5,0,0,0-5,0Z"/></svg>
            <p><strong>Application de peinture semi-épaisse (acrylique et pliolite)</strong></p>
        </div>

        <div class="main-wrapper__labelling" v-scroll-reveal.nomobile="{ delay: 150 }" >Sols</div>

        <div class="section-two__item" v-scroll-reveal.nomobile="{ delay: 150 }" >
             <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>color-rolling-brush</title><path d="M23,2.5H21.75a.25.25,0,0,0-.25.25v1.5a.25.25,0,0,0,.25.25H23a1,1,0,0,0,0-2Z"/><path d="M3,2.5a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h8a1,1,0,0,1,1,1v.325a.25.25,0,0,0,.3.245,3.535,3.535,0,0,1,1.4,0,.25.25,0,0,0,.3-.245V11.5a3,3,0,0,0-3-3H3a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1h.25a.25.25,0,0,0,.25-.25V2.75a.25.25,0,0,0-.25-.25Z"/><rect x="4.5" width="16" height="7" rx="1.5" ry="1.5"/><path d="M10.5,15.5v7A1.5,1.5,0,0,0,12,24h2a1.5,1.5,0,0,0,1.5-1.5v-7a2.5,2.5,0,0,0-5,0Z"/></svg>
            <p><strong>Sols souples</strong></p>
        </div>

        <div class="section-two__item" v-scroll-reveal.nomobile="{ delay: 150 }" >
             <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>color-rolling-brush</title><path d="M23,2.5H21.75a.25.25,0,0,0-.25.25v1.5a.25.25,0,0,0,.25.25H23a1,1,0,0,0,0-2Z"/><path d="M3,2.5a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h8a1,1,0,0,1,1,1v.325a.25.25,0,0,0,.3.245,3.535,3.535,0,0,1,1.4,0,.25.25,0,0,0,.3-.245V11.5a3,3,0,0,0-3-3H3a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1h.25a.25.25,0,0,0,.25-.25V2.75a.25.25,0,0,0-.25-.25Z"/><rect x="4.5" width="16" height="7" rx="1.5" ry="1.5"/><path d="M10.5,15.5v7A1.5,1.5,0,0,0,12,24h2a1.5,1.5,0,0,0,1.5-1.5v-7a2.5,2.5,0,0,0-5,0Z"/></svg>
            <p><strong>Sols PVC</strong></p>
        </div>

        <div class="section-two__item" v-scroll-reveal.nomobile="{ delay: 150 }" >
            <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>color-rolling-brush</title><path d="M23,2.5H21.75a.25.25,0,0,0-.25.25v1.5a.25.25,0,0,0,.25.25H23a1,1,0,0,0,0-2Z"/><path d="M3,2.5a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h8a1,1,0,0,1,1,1v.325a.25.25,0,0,0,.3.245,3.535,3.535,0,0,1,1.4,0,.25.25,0,0,0,.3-.245V11.5a3,3,0,0,0-3-3H3a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1h.25a.25.25,0,0,0,.25-.25V2.75a.25.25,0,0,0-.25-.25Z"/><rect x="4.5" width="16" height="7" rx="1.5" ry="1.5"/><path d="M10.5,15.5v7A1.5,1.5,0,0,0,12,24h2a1.5,1.5,0,0,0,1.5-1.5v-7a2.5,2.5,0,0,0-5,0Z"/></svg>
            <p><strong>Moquettes</strong></p>
        </div>

        <div class="section-two__item" v-scroll-reveal.nomobile="{ delay: 150 }" >
             <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>color-rolling-brush</title><path d="M23,2.5H21.75a.25.25,0,0,0-.25.25v1.5a.25.25,0,0,0,.25.25H23a1,1,0,0,0,0-2Z"/><path d="M3,2.5a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h8a1,1,0,0,1,1,1v.325a.25.25,0,0,0,.3.245,3.535,3.535,0,0,1,1.4,0,.25.25,0,0,0,.3-.245V11.5a3,3,0,0,0-3-3H3a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1h.25a.25.25,0,0,0,.25-.25V2.75a.25.25,0,0,0-.25-.25Z"/><rect x="4.5" width="16" height="7" rx="1.5" ry="1.5"/><path d="M10.5,15.5v7A1.5,1.5,0,0,0,12,24h2a1.5,1.5,0,0,0,1.5-1.5v-7a2.5,2.5,0,0,0-5,0Z"/></svg>
            <p><strong>Parquet stratifiés, flottant</strong></p>
        </div>

        <div class="main-wrapper__labelling" v-scroll-reveal.nomobile="{ delay: 150 }" >Nettoyage de fin de chantier</div>

        <h3 class="main-wrapper__title" v-scroll-reveal.nomobile="{ delay: 150 }" >Nos fournisseurs</h3>

        <div class="section-three__container" v-scroll-reveal.nomobile="{ delay: 150 }" >
            <div class="section-three__container--top">
                <a href="http://www.seguret-decoration.fr/"><img class="placeholder seg" alt="logo Seguret" src="../assets/Logo_seguret.png"/></a>
            <a href="https://www.tollens.com/" ><img class="placeholder tol" alt="logo tollens" src="../assets/Logo_tollens.png"/></a>
            <a href="https://www.seigneuriegauthier.com/" ><img class="placeholder SG" alt="logo Seigneurie Gauthier" src="../assets/Logo_seigneurie.png"/></a>
            </div>
            <div class="section-three__container--bot">
                <a href="http://www.sikkens.com/" ><img class="placeholder sik" alt="logo Sikkens" src="../assets/Logo_sikkens.png"/></a>
            <a href="http://www.texdecor.fr/" ><img class="placeholder tex" alt="logo texdecor" src="../assets/Logo_texdecor.png"/></a>
            <a href="https://www.gerflor.fr/" ><img class="placeholder ger" alt="logo gerflor" src="../assets/Logo_gerflor.png"/></a>
            </div>
        </div>
    </div>

    <div class="banner__prestations">
            <div class="banner__prestations--container">
                <h3>Nos principaux fournisseurs sont les entreprises <span><strong>Tollens</strong></span>, <span><strong>Seigneurie</strong></span> et <span><strong>Seguret</strong></span></h3>
                <p>Ainsi, nous pouvons garantir la qualité et la diversité de nos produits. Nous sommes en mesure de profiter des conseils de professionnels expérimentés et connaisseurs ainsi que de solutions adaptées à tout type de contrainte et de demande.</p>
            </div>
    </div>

    <div class="banner-fullImage">
        <div class="banner-fullImage__overlay"></div>
        <div class="banner-fullImage__container">
            <svg class="svg-2" id="Light" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs></defs><title>plant</title><path class="cls-1" d="M17,8.5a5,5,0,0,0-5,5A5,5,0,0,0,17,8.5Z"/><path class="cls-1" d="M5.5,15A6.5,6.5,0,0,1,12,21.5,6.5,6.5,0,0,1,5.5,15Z"/><path class="cls-1" d="M18.5,15A6.5,6.5,0,0,0,12,21.5,6.5,6.5,0,0,0,18.5,15Z"/><path class="cls-1" d="M7,8.5a5,5,0,0,1,5,5A5,5,0,0,1,7,8.5Z"/><path class="cls-1" d="M12,.5a4.95,4.95,0,0,1,0,7A4.95,4.95,0,0,1,12,.5Z"/><line class="cls-1" x1="12" y1="23.5" x2="12" y2="7.5"/></svg>
        <div class="banner-fullImage__text">
            <p>Nous sommes vigilants quant à la qualité environnementale des produits proposés (normes NF ENVIRONNEMENT, EU ECOLABEL, faible émission de C.O.V., sans odeurs, sans solvant, biodégradable et non dangereux). Nous veillons à ce que nos produits soient le plus en corrélation possible avec la demande de notre client et qu'il soit techniquement le plus adapté et le plus inovant.</p>
        </div>
        </div>

    </div>
</div> 
</template>

<script>
export default {
    metaInfo() {
            return {
                title: 'Lacombe - Prestations', 
                meta: [
                    { charset: 'utf-8' },
                    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                    { vmid: 'description', name: 'description', content: "Découvrez nos prestations et nos fournisseurs de qualité"},
                ]
            }
        },
}
</script>

<style scoped lang="scss">

$wrapperSize : 1300px;

.cls-1{fill:none;stroke:#FFFFFF;stroke-linecap:round;stroke-linejoin:round;}

    .banner-image {
        width: 100%;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        padding: 0px 12px;
        box-sizing: border-box;
        position: relative;
        background-image: url('../assets/maisoncastres.jpg');
        background-size: cover;
        z-index: 2;

        h1 {
            margin: 0;
            font-size: 38px;
            z-index: 5;
            font-weight: bold;
        }
        h2 {
            margin: 0;
            margin-top: 8px;
            font-size: 20px;
            font-weight: 200;
            z-index: 5;
            color: #E9E9E9;
        }
    }

.banner__overlay {
    width: 100%;
    height: 100%;       
    background-color: #DA973A;
    opacity: 0.8;
    position: absolute;
    z-index: 3;
  }

  .main-wrapper {
      width: $wrapperSize;
      margin-left: auto;
      margin-right: auto;
      padding: 45px 12px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      
      h3 {
          font-size: 18px;
          font-weight: 500;
          margin: 40px 0px;
          margin-top: 20px;
      }

      p {
          margin-bottom: 28px;
      }
  }

  .main-wrapper .main-wrapper__title {

      h3 {
          font-weight: inherit;
          font-weight: bold;
      }
    text-transform: uppercase;
    font-size: 30px;
    color: #DA973A;
    margin-top: 40px;
    margin-bottom: 50px;
    
    font-weight: bold;
    width: 50%;
  }

  .section-one {
      width: 100%;
      display: flex;
      margin: 20px 0px;

      img {
          width: 460px;
          height: 530px;
      }
  }

  .section-one__text {
      width: 700px;
      display: flex;
      flex-direction: column;
      margin-left: 80px;
      box-sizing:border-box;

      .section-one__subtitle {
        color: white;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 150px;
        background-color: #DA973A;
        font-weight: bold;
        text-transform: uppercase;
        padding: 6px 10px;
        border-radius: 4px;
        margin: 0px;
        margin-bottom: 30px;
        font-size: 23px;
      }

      p {
          margin: 0;
          margin-bottom: 24px;
          font-size: 20px;
      }

      .section-one__text--item {
          width: 100%;
          display: flex;
          align-items: flex-start;
          margin-bottom: 16px;
          box-sizing: border-box;

          span {
              margin-right: 16px;
              opacity: 0.5;
              font-size: 10px;
              flex-shrink: 0;
          }
          p {
              opacity: 0.5;
              margin: 0; 
              margin-top: -8px;
          }
      }
  }

  .main-wrapper .main-wrapper__title:nth-child(3) {
      margin-top: 80px;
      margin-bottom: 0px;
  }

  .section-one__text .section-one__subtitle:nth-child(8) {
          margin-top: 50px;
      }

  .main-wrapper__labelling {
      border-radius: 25px;
      background-color: #DE991B;
      padding: 12px 20px;
      align-self: flex-start;
      font-size: 20px;
      font-weight: bold;
      color: white;
      margin-bottom: 40px;
      margin-top: 40px;
  }

  .section-two__item {
      width: $wrapperSize;
      display: flex;
      align-items: flex-start;
      margin-bottom: 32px;
      box-sizing: border-box;
      padding-left: 40px;

      .svg {
          fill:#DA973A;
          width: 20px;
          height: 20px;
          margin-right: 16px; 
          flex-shrink: 0;
      }

      p {
          margin: 0;
          font-size: 21px;
          margin-top: -3px;
          font-weight: 100;

          strong {
              font-weight: 600;
          }
      }
  }

  .section-three__container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 60px;
  }

  .section-three__container--top {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
  }

  .section-three__container--bot {
      display: flex;
      width: 70%;
      align-items: center;
      justify-content: space-between;
  }

  .placeholder {
      width: 300px;
      height:215px;
      flex-shrink: 0;
  }

  .SG {

  }

  .tol {

  }

  .seg {
    margin-right: 0;
  }

  .ger {
      width: 200px;
      height:130px;
  }

  .tex {
       width: 200px;
      height:130px;
  }

  .sik {
     width: 200px;
      height:130px;
  }

  .banner__prestations {
      width: 100%;
      height: 275px;
      display: flex;
      align-items: center;
      background-color: #DE991B;
      box-sizing: border-box;
      padding: 0px 12px;
  }

  .banner__prestations--container {
      width: $wrapperSize;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      h3 {
          font-size: 28px;
          font-weight: 100;
          width: 50%;
          margin: 0;
          margin-bottom: 25px;
        
          span {
              font-weight: 800;
              text-transform: uppercase;
          }
      }

      p {
          font-size: 16px;
          line-height: 19px;
          font-weight: 100;
          line-height: 30px;
          margin: 0;
      }
  }

  .svg-2 {
      width: 100px;
      height: 100px;
      fill:#FFFFFF;
      transform: rotate(45deg);
  }

  .banner-fullImage {
      width: 100%;
      height: 400px;
      background-image: url('../assets/fleurs.jpg');
      position: relative;
      background-size: cover;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding: 0px 12px;
      margin-bottom: 48px;
  }

  .banner-fullImage__overlay {
      width: 100%;
      height: 400px;
      background-color: rgba(0,0,0,0.7);
      position: absolute;
      z-index: 3;

  }

  .banner-fullImage__container {
      z-index:5;
      width: 55%;
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
          font-size: 18px;
          line-height: 30px;
          margin-top: 38px;
      }
  }

  @media (max-width: 1300px) {
      .main-wrapper {
      width: 1000px;
      margin-left: auto;
      margin-right: auto;
      padding: 45px 12px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      
      h3 {
          font-size: 18px;
          font-weight: 500;
          margin: 40px 0px;
          margin-top: 20px;
      }

      p {
          margin-bottom: 28px;
      }
  }

  .section-two__item {
      width: 1000px;
      display: flex;
      align-items: flex-start;
      margin-bottom: 32px;
      box-sizing: border-box;
      padding-left: 40px;

      .svg {
          width: 20px;
          height: 20px;
          margin-right: 16px;
          flex-shrink: 0;
      }

      p {
          margin: 0;
          font-size: 21px;
          margin-top: -3px;
          font-weight: 100;
      }
  }

  .banner__prestations--container {
      width: 1000px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      h3 {
          font-size: 28px;
          font-weight: 300;
          width: 50%;
          margin: 0;
          margin-bottom: 25px;
        
          span {
              font-weight: 600;
              text-transform: uppercase;
          }
      }

      p {
          font-size: 16px;
          line-height: 19px;
          margin: 0;
      }
  }

  .main-wrapper__labelling {
      border-radius: 25px;
      background-color: #DA973A;
      padding: 8px 16px;
      align-self: flex-start;
      font-size: 18px;
      font-weight: 500;
      color: white;
      margin-bottom: 40px;
      margin-top: 40px;
  }

  }

  @media (max-width: 1000px) {
      .main-wrapper {
      width: 100%;
      
      h3 {
          font-size: 18px;
          font-weight: 500;
          margin: 40px 0px;
          margin-top: 20px;
      }

      p {
          margin-bottom: 28px;
          font-size: 15px
      }
  }

  .main-wrapper .main-wrapper__title {
    text-transform: uppercase;
    font-size: 30px;
    color: #DA973A;
    margin-top: 40px;
    margin-bottom: 50px;
    width: 80%;
  }

  .section-one__text {
      width: 700px;
      display: flex;
      flex-direction: column;
      margin-left: 40px;
      box-sizing:border-box;

      .section-one__subtitle {
        color: white;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100px;
        background-color: #DA973A;
        text-transform: uppercase;
        padding: 6px 10px;
        border-radius: 4px;
        margin: 0px;
        margin-bottom: 30px;
        font-size: 18px;
      }

      p {
          margin: 0;
          margin-bottom: 24px;
          font-size: 16px;
      }
  }

  .section-one {
      width: 100%;
      display: flex;
      margin: 20px 0px;

      img {
          width: 350px;
          height: 450px;
      }
  }

  .section-two__item {
      width: 100%;
      display: flex;
      align-items: flex-start;
      margin-bottom: 32px;
      box-sizing: border-box;
      padding-left: 40px;

      .svg {
          width: 20px;
          height: 20px;
          margin-right: 16px;
          flex-shrink: 0;
      }

      p {
          margin: 0;
          font-size: 21px;
          margin-top: -3px;
      }
  }

    .section-three__container--top {
      display: flex;
      width: 100%;
      align-items: center;
       justify-content: center;
      flex-wrap: wrap;
  }

  .section-three__container--bot {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
  }
  }


  @media (max-width: 850px) {
      .banner-image {
        h1 {
            margin: 0;
            font-size: 32px;
            z-index: 5;
        }
        h2 {
            margin: 0;
            margin-top: 8px;
            font-size: 18px;
            font-weight: 200;
            z-index: 5;
        }
    }

    .main-wrapper .main-wrapper__title {
    text-transform: uppercase;
    font-size: 26px;
    color: #DA973A;
    margin-top: 40px;
    margin-bottom: 50px;
    width: 100%;
  }

    .section-one {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
  }

  .section-one__text {
      margin-top: 80px;
      width: 100%;
      margin-left: 0px;
      box-sizing: border-box;
  }

  .section-two__item {
      padding-left: 20px;

      .svg {
          width: 20px;
          height: 20px;
          margin-right: 16px;
          flex-shrink: 0;
      }

      p {
          margin: 0;
          font-size: 18px;
          margin-top: -3px;
      }
  }

  .banner__prestations--container {
      h3 {
          font-size: 23px;
          font-weight: 300;
          width: 80%;
          margin: 0;
          margin-bottom: 25px;
        
          span {
              font-weight: 600;
              text-transform: uppercase;
          }
      }

      p {
          font-size: 14px;
          line-height: 19px;
          margin: 0;
      }
  }

  .banner-fullImage {
      height: 500px;
  }

  .banner-fullImage__overlay {
      height: 500px;
  }

  .banner-fullImage__container {
      z-index:5;
      width: 100%;
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
          font-size: 14px;
          line-height: 26px;
          margin-top: 38px;
      }
  }
  }

  @media (max-width: 600px) {
      .banner-image {
          text-align: center;
        h1 {
            margin: 0;
            font-size: 26px;
            z-index: 5;
        }
        h2 {
            margin: 0;
            margin-top: 8px;
            font-size: 14px;
            font-weight: 200;
            z-index: 5;
        }
    }

    .banner__prestations {
        padding: 40px 16px;
        height: auto;
    }
  }

</style>
