<template>
<div>
    <div class="banner-image">
        <div class="banner__overlay"></div>
        <h1>Nos références</h1>
        <h2>Découvrez quelques unes de nos réalisations</h2>
    </div>

    <div class="references-infos">
        <p>Nous travaillons aussi bien avec des <strong>professionnels</strong> du secteur public et privé qu'avec des <strong>particuliers</strong>.</p>
    </div>

    <div class="reference-item__container" v-scroll-reveal.nomobile="{ delay: 150 }" >
        <input type="radio" id="i1" checked name="images" />
	    <input type="radio" id="i2" name="images" />
        <input type="radio" id="i3" name="images" />

        <div class="reference-item__overlay"></div>
        <div  id="one"></div>
        <div  id="two"></div>
        <div  id="three"></div>
        <div class="refItem-text">
            <div class="refItem-text__text">
                <div class="refItem-text__label">Public • Albi</div>
                <h3 class="refItem-text__title">Réaménagement de la Gare d'Albi</h3>
                <p class="refItem-text__description">Travaux de peinture intérieure (murs, plafonds,  boiseries, garde-corps métal), peinture de sol anti-poussière, nettoyage.</p>
            </div>
            <div class="refItem-text__nav">
                <label for="i1" class="refItem-text__nav--select" title="Voir plus de photos ici" id="dot1"></label>
		        <label for="i2" class="refItem-text__nav--select" title="Voir plus de photos ici" id="dot2"></label>
                <label for="i3" class="refItem-text__nav--select" title="Voir plus de photos ici" id="dot3"></label>
            </div>
        </div>
    </div>

    <div class="reference-item__container" v-scroll-reveal.nomobile="{ delay: 150 }" >
        <input type="radio" id="i4" checked name="image" />
	    <input type="radio" id="i5" name="image" />

        <div class="reference-item__overlay"></div>
        <div  id="four"></div>
        <div  id="five"></div>
        <div class="refItem-text">
            <div class="refItem-text__text">
                <div class="refItem-text__label">Privé • Albi</div>
                <h3 class="refItem-text__title">Aménagement d'un magasin DEVRED</h3>
                <p class="refItem-text__description">Travaux de peintures intérieures et extérieures, pose de moquette.</p>
            </div>
            <div class="refItem-text__nav">
                <label for="i4" class="refItem-text__nav--select" title="Voir plus de photos ici" id="dot4"></label>
		        <label for="i5" class="refItem-text__nav--select" title="Voir plus de photos ici" id="dot5"></label>
            </div>
        </div>
    </div>

    <div class="reference-item__container" v-scroll-reveal.nomobile="{ delay: 150 }" >
        <input type="radio" id="i6" checked name="image3" />

        <div class="reference-item__overlay"></div>
        <div  id="six"></div>
        <div class="refItem-text">
            <div class="refItem-text__text">
                <div class="refItem-text__label">Public • Senouillac</div>
                <h3 class="refItem-text__title">Aménagement d'une boulangerie et de quatre bureaux</h3>
                <p class="refItem-text__description">Travaux de peintures intérieures (murs, plafonds, boiseries, nettoyage)</p>
            </div>
            <div class="refItem-text__nav">
                <label for="i6" class="refItem-text__nav--select" id="dot6"></label>
            </div>
        </div>
    </div>

    <div class="reference-item__container" v-scroll-reveal.nomobile="{ delay: 150 }" >
        <input type="radio" id="i7" checked name="image4" />
	    <input type="radio" id="i8" name="image4" />

        <div class="reference-item__overlay"></div>
        <div  id="seven"></div>
        <div  id="eight"></div>
        <div class="refItem-text">
            <div class="refItem-text__text">
                <div class="refItem-text__label">Public • Castres</div>
                <h3 class="refItem-text__title">Aménagement de la gare routière de Castres</h3>
                <p class="refItem-text__description">Travaux de peinture intérieure (toile de verre et peinture des murs, plafonds, boiseries), sols souples, vernis des parquets</p>
            </div>
            <div class="refItem-text__nav">
                <label for="i7" title="Voir plus de photos ici" class="refItem-text__nav--select" id="dot7"></label>
		        <label for="i8" title="Voir plus de photos ici" class="refItem-text__nav--select" id="dot8"></label>
            </div>
        </div>
    </div>

    <div class="reference-item__container" v-scroll-reveal.nomobile="{ delay: 150 }" >
        <input type="radio" id="i9" checked name="image5" />
	    <input type="radio" id="i10" name="image5" />
        <input type="radio" id="i11" name="image5" />
	    <input type="radio" id="i12" name="image5" />

        <div class="reference-item__overlay"></div>
        <div  id="nine"></div>
        <div  id="ten"></div>
        <div  id="eleven"></div>
        <div  id="twelve"></div>
        <div class="refItem-text">
            <div class="refItem-text__text">
                <div class="refItem-text__label">Public • Marssac-sur-Tarn</div>
                <h3 class="refItem-text__title">Construction d'un complexe omnisports</h3>
                <p class="refItem-text__description">Travaux de peinture intérieure et extérieure, nettoyage de fin de chantier</p>
            </div>
            <div class="refItem-text__nav">
                <label for="i9" title="Voir plus de photos ici" class="refItem-text__nav--select" id="dot9"></label>
		        <label for="i10" title="Voir plus de photos ici" class="refItem-text__nav--select" id="dot10"></label>
                <label for="i11" title="Voir plus de photos ici" class="refItem-text__nav--select" id="dot11"></label>
		        <label for="i12" title="Voir plus de photos ici" class="refItem-text__nav--select" id="dot12"></label>
            </div>
        </div>
    </div>

    <div class="reference-item__container" v-scroll-reveal.nomobile="{ delay: 150 }" >
        <input type="radio" id="i13" checked name="image6" />
	    <input type="radio" id="i14" name="image6" />

        <div class="reference-item__overlay"></div>
        <div  id="thirteen"></div>
        <div  id="fourteen"></div>
        <div class="refItem-text">
            <div class="refItem-text__text">
                <div class="refItem-text__label">Public • Cahuzac-sur-Vere</div>
                <h3 class="refItem-text__title">Rénovation de la maison des associations</h3>
                <p class="refItem-text__description">Travaux de peinture intérieure, sols souples et nettoyage</p>
            </div>
            <div class="refItem-text__nav">
                <label for="i13" title="Voir plus de photos ici" class="refItem-text__nav--select" id="dot13"></label>
		        <label for="i14" title="Voir plus de photos ici" class="refItem-text__nav--select" id="dot14"></label>
            </div>
        </div>
    </div>

    <div class="reference-item__container" v-scroll-reveal.nomobile="{ delay: 150 }" >
        <input type="radio" id="i15" checked name="image20" />
	    <input type="radio" id="i16" name="image20" />
        <input type="radio" id="i17" name="image20" />
	    <input type="radio" id="i18" name="image20" />

        <div class="reference-item__overlay"></div>
        <div  id="fifteen"></div>
        <div  id="sixteen"></div>
        <div  id="seventeen"></div>
        <div  id="eighteen"></div>
        <div class="refItem-text">
            <div class="refItem-text__text">
                <div class="refItem-text__label">Particulers • Gaillac</div>
                <h3 class="refItem-text__title">Réaménagement d'une maison d'habitation</h3>
                <p class="refItem-text__description">Travaux de peinture intérieure, sols souples (cuisine, arrière cuisine, cave, buanderie, salles de bain, wc, bureau, chambre et cage d'escalier</p>
            </div>
            <div class="refItem-text__nav">
                <label for="i15" title="Voir plus de photos ici" class="refItem-text__nav--select" id="dot15"></label>
		        <label for="i16" title="Voir plus de photos ici" class="refItem-text__nav--select" id="dot16"></label>
                <label for="i17" title="Voir plus de photos ici" class="refItem-text__nav--select" id="dot17"></label>
		        <label for="i18" title="Voir plus de photos ici" class="refItem-text__nav--select" id="dot18"></label>
            </div>
        </div>
    </div>

    <div class="reference-item__container" v-scroll-reveal.nomobile="{ delay: 150 }" >
        <input type="radio" id="i19" checked name="image8" />
	    <input type="radio" id="i20" name="image8" />

        <div class="reference-item__overlay"></div>
        <div  id="nineteen"></div>
        <div  id="twenty"></div>
        <div class="refItem-text">
            <div class="refItem-text__text">
                <div class="refItem-text__label">Particuliers • Castres</div>
                <h3 class="refItem-text__title">Surélévation partielle d'une maison d'habitation</h3>
                <p class="refItem-text__description">Travaux de peinture en façade, peinture de la sous-face de la toiture peintures intérieures</p>
            </div>
            <div class="refItem-text__nav">
                <label for="i19" title="Voir plus de photos ici" class="refItem-text__nav--select" id="dot19"></label>
		        <label for="i20" title="Voir plus de photos ici" class="refItem-text__nav--select" id="dot20"></label>
            </div>
        </div>
    </div>

    <div class="reference-item__container" v-scroll-reveal.nomobile="{ delay: 150 }" >
        <input type="radio" id="i21" checked name="image9" />
	    <input type="radio" id="i22" name="image9" />

        <div class="reference-item__overlay"></div>
        <div  id="twentyone"></div>
        <div  id="twentytwo"></div>
        <div class="refItem-text">
            <div class="refItem-text__text">
                <div class="refItem-text__label">Privé • Saix</div>
                <h3 class="refItem-text__title">Aménagement d'un restaurant La Boucherie</h3>
                <p class="refItem-text__description">Travaux de peinture et de sols souples</p>
            </div>
            <div class="refItem-text__nav">
                <label for="i21" title="Voir plus de photos ici" class="refItem-text__nav--select" id="dot21"></label>
		        <label for="i22" title="Voir plus de photos ici" class="refItem-text__nav--select" id="dot22"></label>
            </div>
        </div>
    </div>

    <div class="reference-item__container" v-scroll-reveal.nomobile="{ delay: 150 }" >
        <input type="radio" id="i23" checked name="image10" />
	    <input type="radio" id="i24" name="image10" />

        <div class="reference-item__overlay"></div>
        <div  id="twentythree"></div>
        <div  id="twentyfour"></div>
        <div class="refItem-text">
            <div class="refItem-text__text">
                <div class="refItem-text__label">Privé • Albi</div>
                <h3 class="refItem-text__title">Réaménagement de l'office du tourisme d'Albi</h3>
                <p class="refItem-text__description">Travaux de peinture intérieure, sol souple et vitrines</p>
            </div>
            <div class="refItem-text__nav">
                <label for="i23" title="Voir plus de photos ici" class="refItem-text__nav--select" id="dot23"></label>
		        <label for="i24" title="Voir plus de photos ici" class="refItem-text__nav--select" id="dot24"></label>
            </div>
        </div>
    </div>

    

    <div class="wrapper-more">
        <div class="loadMore">Voir plus de réalisations</div>

        <table class="lightbox">
            <thead>
                <tr>
                <th class="year">Date</th>
                <th class="name">Client</th>
                <th class="subject">Sujet</th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td class="year">2018</td>
                    <td class="name">OPH CASTRES</td>
                    <td class="subject">Construction de 28 logements, rue Franchet d'Esperey - Lardaillé à CASTRES - Lot PEINTURE INTERIEURE ET EXTERIEURE</td>
                </tr>

                 <tr>
                    <td class="year">2018</td>
                    <td class="name">OPPIDEA</td>
                    <td class="subject">Campus d'innovation TOULOUSE - TMA</td>
                </tr>

                 <tr>
                    <td class="year">2018</td>
                    <td class="name">CHAMBRE D'AGRICULTURE DU TARN</td>
                    <td class="subject">Travaux de réaménagement des bureaux de la chambre d'agriculture du TARN à ALBI</td>
                </tr>

                <tr>
                    <td class="year">2018</td>
                    <td class="name">Commune de Bourg Saint Bernard</td>
                    <td class="subject">Remise en état de la Salle polyvalente Robert de LAPLAGNOLLE à BOURG SAINT BERNARD - LOT PEINTURE</td>
                </tr>

                <tr>
                    <td class="year">2018</td>
                    <td class="name">Mairie de Castres</td>
                    <td class="subject">Projet d'amélioration des conditions d'accueil et d'extension de la tribune Rui - Stade Pierre Antoine à CASTRES - Lot PEINTURE</td>
                </tr>

                <tr>
                    <td class="year">2018</td>
                    <td class="name">NOLT</td>
                    <td class="subject">Extension d'un bâtiment commercial NETTO à CARMAUX - Lot Peinture</td>
                </tr>

                <tr>
                    <td class="year">2018</td>
                    <td class="name">MAIRIE DE LAUZERVILLE</td>
                    <td class="subject">Restructuration et extension du groupe scolaire à LAUZERVILLE - Lot Peinture</td>
                </tr>

                <tr>
                    <td class="year">2018</td>
                    <td class="name">THEMELIA</td>
                    <td class="subject">Extension et restructuration du collège Jacques Durand à PUYLAURENS - Lot Peinture-Faïence-Sols souples</td>
                </tr>

                <tr>
                    <td class="year">2017</td>
                    <td class="name">Massoutier et fils</td>
                    <td class="subject">Création d'un pôle pénitentiaire de formation à l'usage des armes à MURET - Lot PEINTURE Pour le compte de : Ministère de la Justice</td>
                </tr>

                <tr>
                    <td class="year">2017</td>
                    <td class="name">CONSEIL GENERAL HAUTE GARONNE</td>
                    <td class="subject">Reconstruction du Collège Georges Chaumeton à l'UNION - M146/15 - Lot REVETEMENT ET PEINTURE</td>
                </tr>

                <tr>
                    <td class="year">2017</td>
                    <td class="name">SPL MIDI-PYRENEES CONSTRUCTION</td>
                    <td class="subject">Extension et réhabilitation du Lycée Victor Hugo à GAILLAC - Lot PEINTURE - NETTOYAGE - SIGNALETIQUE -</td>
                </tr>

                <tr>
                    <td class="year">2017</td>
                    <td class="name">PIERRE FABRE MEDICAMENT</td>
                    <td class="subject">Réhabilitation du site Péraudel à CASTRES - Pierre Fabre Médicaments - Lot PEINTURE</td>
                </tr>

                <tr>
                    <td class="year">2017</td>
                    <td class="name">CENTRE HOSPITALIER DE LAVAUR</td>
                    <td class="subject">Aménagement de locaux du personnel et de bureaux à l'hôpital Guiraud (R+1 Zone B) à LAVAUR - Lot Revêtements muraux - Peinture</td>
                </tr>

                <tr>
                    <td class="year">2017</td>
                    <td class="name">MAIRIE DE VALDERIES</td>
                    <td class="subject">Rénovation - Mise aux normes - Réhabilitation thermique de la salle polyvalente de VALDERIES - Lot Peinture - Nettoyage</td>
                </tr>

                <tr>
                    <td class="year">2017</td>
                    <td class="name">COMMUNE DE NAJAC</td>
                    <td class="subject">Aménagement d'un centre d'interprêtation Grand Site Midi-Pyrénées à NAJAC - Lot PEINTURES / NETTOYAGE</td>
                </tr>

                <tr>
                    <td class="year">2017</td>
                    <td class="name">BOUYGUES BATIMENT CSO</td>
                    <td class="subject">Rénovation des bâtiments du centre de formation de l'ENAC à MURET - Lot Peinture</td>
                </tr>

                <tr>
                    <td class="year">2017</td>
                    <td class="name">ICADE PROMOTION</td>
                    <td class="subject">Restructuration de l'internat des 36 ponts - Création de logements étudiants à TOULOUSE - LOT PEINTURE</td>
                </tr>

                <tr>
                    <td class="year">2017</td>
                    <td class="name">MAIRIE CAMBON D'ALBI</td>
                    <td class="subject">Extension et réaménagement de la crèche Pirouette - Galipette à CAMBON D'ALBI - Lot peinture</td>
                </tr>

                <tr>
                    <td class="year">2017</td>
                    <td class="name">MAIRIE DE FREJEVILLE</td>
                    <td class="subject">Construction d'une Mairie à FREJEVILLE - Lot Peinture intérieure</td>
                </tr>

                <tr>
                    <td class="year">2017</td>
                    <td class="name">CCI CASTRES MAZAMET</td>
                    <td class="subject">Extension hall départs et arrivées - salles réunion - Aéroport Castres - Mazamet - Lot Peinture</td>
                </tr>

                <tr>
                    <td class="year">2017</td>
                    <td class="name">TARN HABITAT</td>
                    <td class="subject">Construction de 14 maisons individuelles à LABASTIDE DE LEVIS - Lot Peinture-Nettoyage</td>
                </tr>
                <tr>
                    <td class="year">2017</td>
                    <td class="name">MAIRIE DE LAVALETTE</td>
                    <td class="subject">Construction d'une école maternelle de 180 m2 à LAVALETTE - Lot Peinture, sols souples</td>
                </tr>

                <tr>
                    <td class="year">2017</td>
                    <td class="name">MAIRIE DE BERNAC</td>
                    <td class="subject">Extension de la MJC pour l'aménagement d'une salle multi-activité scolaire à BERNAC - Lot Peinture</td>
                </tr>

                <tr>
                    <td class="year">2017</td>
                    <td class="name">Mairie de Montbéron</td>
                    <td class="subject">Extension de la salle des fêtes de MONTBERON - lot Peinture</td>
                </tr>

                <tr>
                    <td class="year">2017</td>
                    <td class="name">BOUYGUES BATIMENT CSO</td>
                    <td class="subject">TOULOUSE BELLEFONTAINE - Le Bois Miralh - Création de 48 logements collectifs - Lot Peinture</td>
                </tr>

                <tr>
                    <td class="year">2017</td>
                    <td class="name">Mairie de Saix</td>
                    <td class="subject">Aménagement intérieur de la maison des associations du bourg centre de SAIX - Lot Peinture</td>
                </tr>

                <tr>
                    <td class="year">2016</td>
                    <td class="name">LECLERC GAILLAC</td>
                    <td class="subject">Transformation ancienne caféteria - LECLERC GAILLAC - lot PEINTURE</td>
                </tr>
                <tr>
                    <td class="year">2016</td>
                    <td class="name">Mairie de Gaillac</td>
                    <td class="subject">Rénovation du Gymnase Albert Camus à GAILLAC -Lot Peinture - sols souples - façades</td>
                </tr>
                <tr>
                    <td class="year">2016</td>
                    <td class="name">MAIRIE DE VABRE</td>
                    <td class="subject">Rénovation du foyer de la salle polyvalente à VABRE- Lot PEINTURE</td>
                </tr>

                <tr>
                    <td class="year">2016</td>
                    <td class="name">Hopital de Lavaur</td>
                    <td class="subject">Construction d'un Bâtiment SSR et Gynéco-Obstétrique au Centre Hospitalier de LAVAUR-Lot PEINTURE</td>
                </tr>

                <tr>
                    <td class="year">2016</td>
                    <td class="name">PROMOLOGIS SA</td>
                    <td class="subject">Construction de 17 logements collectifs et 5 maisons à BALMA- Lot PEINTURE - NETTOYAGE</td>
                </tr>

                <tr>
                    <td class="year">2016</td>
                    <td class="name">Mairie de Toulouse</td>
                    <td class="subject">Centre d'animation La Terrasse - Projet de restauration séniors à TOULOUSE-Lot SOLS SOUPLES - FAIENCE - PEINTURES</td>
                </tr>

                <tr>
                    <td class="year">2016</td>
                    <td class="name">EIFFAGE CONSTRUCTION</td>
                    <td class="subject">Résidence RAYSSAC à ALBI - Façades</td>
                </tr>

                <tr>
                    <td class="year">2016</td>
                    <td class="name">EIFFAGE IMMOBILIER M.-PYRENEES</td>
                    <td class="subject">Construction de 34 logements à CASTANET TOLOSAN - Lot peinture</td>
                </tr>

                <tr>
                    <td class="year">2016</td>
                    <td class="name">Mairie d'Aussillon</td>
                    <td class="subject">Construction d'une crèche / pôle enfance - AUSSILLON - LOT PEINTURE</td>
                </tr>

                <tr>
                    <td class="year">2016</td>
                    <td class="name">SEM 81</td>
                    <td class="subject">Mise en conformité accessibilité du collége de CORDES SUR CIEL - OP 2978 - Lot SOLS SOUPLES - PEINTURE</td>
                </tr>

                <tr>
                    <td class="year">2016</td>
                    <td class="name">Mairie de Toulouse</td>
                    <td class="subject">Reconstruction du Centre Petite enfance et multi accueil AMOUROUX - LOT Peinture</td>
                </tr>

                <tr>
                    <td class="year">2016</td>
                    <td class="name">DIRECTION DES FINANCES PUBLIQUES DU TARN</td>
                    <td class="subject">Réaménagement du Centre des Finances Publiques d'ALBI - Lot PEINTURE</td>
                </tr>

                <tr>
                    <td class="year">2016</td>
                    <td class="name">BOUYGUES</td>
                    <td class="subject">Réhabilitation de 180 logements TOULOUSE FONTANELLES - Lot peinture</td>
                </tr>

                <tr>
                    <td class="year">2016</td>
                    <td class="name">COLLEGE BALZAC ALBI</td>
                    <td class="subject">Mise en conformité accessibilité du Collége Balzac à ALBI - OP2978 A1 - Lot SOLS SOUPLES - PEINTURE</td>
                </tr>

                <tr>
                    <td class="year">2016</td>
                    <td class="name">COLLEGE VICTOR HUGO CARMAUX</td>
                    <td class="subject">Mise en conformité accessibilité du collège Victor Hugo à CARMAUX - OP 2978 A2 - Lot SOLS SOUPLE - PEINTURE</td>
                </tr>

                <tr>
                    <td class="year">2016</td>
                    <td class="name">UNIVERSITE PAUL SABATIER</td>
                    <td class="subject">Divers travaux de peinture</td>
                </tr>


            </tbody>
            
        </table>

    </div>

    

</div>
    
</template>

<script>
export default {
    metaInfo() {
            return {
                title: 'Lacombe - Références', 
                meta: [
                    { charset: 'utf-8' },
                    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                    { vmid: 'description', name: 'description', content: "Découvrez en images quelques unes de nos réalisations pour des clients professionnels et des particuliers"},
                ]
            }
        },
}

</script>

<style lang="scss" scoped>



.year {
 width: 56px;
 text-align:left;
 font-size: 12px;
}

.name{
 width: 140px;
 padding-right: 20px;
 text-align:left;
 font-size: 12px;
 font-weight: 700;
}

.subject {
text-align:left;
font-size: 12px;
}

.lightbox {
    display: none;
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    border-collapse: collapse;
    box-sizing: border-box;
    margin: 0px 10px;
    margin-bottom: 40px;

    thead {
        tr {
            th {
                color: #4E74B7;
            }
        }
    }


    tbody {
        tr {
            border-bottom: 1px solid #DEDEDE;
            td {
padding: 16px 0px;
            }

            .name {
                padding-right: 20px;
            }
        
    }

    }
    
}

.on {
    display: flex;
}

a {
    text-decoration: none;
    color: inherit;
}
.banner-image {
        width: 100%;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        padding: 0px 12px;
        box-sizing: border-box;
        position: relative;
        background-image: url('../assets/locaux.jpg');
        background-size: cover;
        z-index: 2;

        h1 {
            margin: 0;
            font-size: 38px;
            z-index: 5;
        }
        h2 {
            margin: 0;
            margin-top: 8px;
            font-size: 20px;
            font-weight: 200;
            z-index: 5;
            color: #E9E9E9;
        }
    }

.banner__overlay {
    width: 100%;
    height: 100%;       
    background-color: #4E74B7;
    opacity: 0.9;
    position: absolute;
    z-index: 3;
  }

  .references-infos {
      width: 100%;
      display: flex;
      align-items: center;
      height: 250px;
      justify-content: center;
      text-align: center;
      padding: 0px 12px;
      box-sizing: border-box;

      p {
          width: 55%;
          font-size: 26px;
          color: #5678AF;
          line-height: 38px;
      }
  }



  .reference-item__container {
      width: 100%;
      height: 100vh;
      position: relative;
      display: flex;
      align-items: flex-end;
      box-sizing: border-box;
       justify-content: center;

      input {
          display: none;
      }
  }

  .disablescroll {
  overflow: hidden;
}

  .reference-item__overlay {
      background-image: linear-gradient(rgba(33,33,33, 0), rgba(0,0,0, 0.5));
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 3;
  }

  .refItem-text {
      display: flex;
      justify-content: space-between;
      width: 90%;
      margin: 30px 72px;
      box-sizing: border-box;
  }

  .refItem-text__text {
      width: 60%;
      display: flex;
      flex-direction: column;
      z-index: 5;
      color: white;
      box-sizing: border-box;
  }

  .refItem-text__label {
      margin-bottom: 16px;
      text-transform: uppercase;
      font-weight: 700;
  }

  .refItem-text__title {
      margin: 0;
      font-weight: 700;
      font-size: 23px;
  }

  .refItem-text__description {
      font-weight: 100;
  }

  #one{
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/gareAlbi.jpg');
      z-index: -1;
      background-size: cover;
  }

  #two {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/gareAlbi2.jpg');
      z-index: -2;
       background-size: cover;
  }

  #three {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/gareAlbi3.jpg');
      z-index: -2;
       background-size: cover;
  }

  #four {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/devred1.jpg');
      z-index: -2;
       background-size: cover;
  }

  #five {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/devred2.jpg');
      z-index: -2;
       background-size: cover;
  }

  #six {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/boulangerie.jpg');
      z-index: -2;
       background-size: cover;
  }

  #seven {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/gareCastres.jpg');
      z-index: -2;
       background-size: cover;
  }

  #eight {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/gareCastres2.jpg');
      z-index: -2;
       background-size: cover;
  }

  #nine {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/omni1.jpg');
      z-index: -2;
       background-size: cover;
  }

  #ten {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/omni2.jpg');
      z-index: -2;
       background-size: cover;
  }

  #eleven {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/omni3.jpg');
      z-index: -2;
       background-size: cover;
  }

  #twelve {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/omni4.jpg');
      z-index: -2;
       background-size: cover;
  }

  #thirteen{
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/maisonAsso.jpg');
      z-index: -2;
       background-size: cover;
  }

  #fourteen {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/maisonAsso2.jpg');
      z-index: -2;
       background-size: cover;
  }

  #fifteen {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/maisonG1.jpg');
      z-index: -2;
       background-size: cover;
  }

  #sixteen {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/maisonG4.jpg');
      z-index: -2;
       background-size: cover;
  }

  #seventeen {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/maisonG3.jpg');
      z-index: -2;
       background-size: cover;
  }

  #eighteen {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/maisonG2.jpg');
      z-index: -2;
       background-size: cover;
  }

  #nineteen {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/maisonC1.jpg');
      z-index: -2;
       background-size: cover;
  }

  #twenty {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/maisonC2.jpg');
      z-index: -2;
       background-size: cover;
  }

  #twentyone {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/boucherie2.jpg');
      z-index: -2;
       background-size: cover;
  }

  #twentytwo {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/boucherie.jpg');
      z-index: -2;
       background-size: cover;
  }

  #twentythree {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/officedutourisme.jpg');
      z-index: -2;
       background-size: cover;
  }

  #twentyfour {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../assets/officedutourisme2.jpg');
      z-index: -2;
       background-size: cover;
  }

  .refItem-text__nav {
      display: flex;
      z-index: 5;
      align-items: flex-end;
      padding: 30px 0px;
  }

  #dot1, #dot2, #dot3, #dot4, #dot5, #dot6, #dot7, #dot8, #dot9, #dot10, #dot11, #dot12, #dot13, #dot14, #dot15, #dot16, #dot17, #dot18, #dot19, #dot20, #dot21, #dot22, #dot23, #dot24 {
      width: 64px;
      height: 16px;
      border-radius: 25px;
      margin-right: 8px;
      cursor: pointer;
      background-color: rgba(245, 245, 245, 0.3);
  }

.wrapper-more {
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}


  .loadMore {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #5678AF;
      color: #5678AF;
      border-radius: 25px;
      cursor: pointer;
      align-self: center;
      margin: 40px 12px;
      padding: 10px 22px;

      &:hover {
          background-color: #5678AF;
          color: white;
          transition: .3s ease-in-out;
      }
  }

  #i1:checked ~ #one,
#i2:checked ~ #two,
#i3:checked ~ #three,
#i4:checked ~ #four,
#i5:checked ~ #five,
#i6:checked ~ #six,
#i7:checked ~ #seven,
#i8:checked ~ #eight,
#i9:checked ~ #nine,
#i10:checked ~ #ten,
#i11:checked ~ #eleven,
#i12:checked ~ #twelve,
#i13:checked ~ #thirteen,
#i14:checked ~ #fourteen,
#i15:checked ~ #fifteen,
#i16:checked ~ #sixteen,
#i17:checked ~ #seventeen,
#i18:checked ~ #eighteen,
#i19:checked ~ #nineteen,
#i20:checked ~ #twenty,
#i21:checked ~ #twentyone,
#i22:checked ~ #twentytwo,
#i23:checked ~ #twentythree,
#i24:checked ~ #twentyfour {
	z-index: 2;
	animation: scroll .3s ease-in-out;
}

#i1:checked ~ .refItem-text > .refItem-text__nav > #dot1 {background-color: white;}
#i2:checked ~ .refItem-text > .refItem-text__nav > #dot2 {background-color: white;}
#i3:checked ~ .refItem-text > .refItem-text__nav > #dot3 {background-color: white;}
#i4:checked ~ .refItem-text > .refItem-text__nav > #dot4 {background-color: white;}
#i5:checked ~ .refItem-text > .refItem-text__nav > #dot5 {background-color: white;}
#i6:checked ~ .refItem-text > .refItem-text__nav > #dot6 {background-color: white;}
#i7:checked ~ .refItem-text > .refItem-text__nav > #dot7 {background-color: white;}
#i8:checked ~ .refItem-text > .refItem-text__nav > #dot8 {background-color: white;}
#i9:checked ~ .refItem-text > .refItem-text__nav > #dot9 {background-color: white;}
#i10:checked ~ .refItem-text > .refItem-text__nav > #dot10 {background-color: white;}
#i11:checked ~ .refItem-text > .refItem-text__nav > #dot11 {background-color: white;}
#i12:checked ~ .refItem-text > .refItem-text__nav > #dot12 {background-color: white;}
#i13:checked ~ .refItem-text > .refItem-text__nav > #dot13 {background-color: white;}
#i14:checked ~ .refItem-text > .refItem-text__nav > #dot14 {background-color: white;}
#i15:checked ~ .refItem-text > .refItem-text__nav > #dot15 {background-color: white;}
#i16:checked ~ .refItem-text > .refItem-text__nav > #dot16 {background-color: white;}
#i17:checked ~ .refItem-text > .refItem-text__nav > #dot17 {background-color: white;}
#i18:checked ~ .refItem-text > .refItem-text__nav > #dot18 {background-color: white;}
#i19:checked ~ .refItem-text > .refItem-text__nav > #dot19 {background-color: white;}
#i20:checked ~ .refItem-text > .refItem-text__nav > #dot20 {background-color: white;}
#i21:checked ~ .refItem-text > .refItem-text__nav > #dot21 {background-color: white;}
#i22:checked ~ .refItem-text > .refItem-text__nav > #dot22 {background-color: white;}
#i23:checked ~ .refItem-text > .refItem-text__nav > #dot23 {background-color: white;}
#i24:checked ~ .refItem-text > .refItem-text__nav > #dot24 {background-color: white;}

@keyframes scroll {
	0% { opacity: .8;}
	100% { opacity: 1;}
}




  @media(min-width: 1300px) {
      .references-infos {
      p {
          width: 45%;
      }
  }

  .reference-item__container {
      height: 100vh;
  }

  .refItem-text {
      margin: 50px 82px;
  }

  .refItem-text__text {
      width: 45%;
  }

  .year {
 width: 56px;
 text-align:left;
 font-size: 14px;
}

.name{
 width: 140px;
 padding-right: 20px;
 text-align:left;
 font-size: 14px;
 font-weight: 700;
}

.subject {
text-align:left;
font-size: 14px;
}

  .refItem-text__label {
      margin-bottom: 16px;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 600;
  }

  .refItem-text__title {
      margin: 0;
      font-weight: 700;
      font-size: 28px;
  }

  .refItem-text__description {
      font-weight: 100;
      font-size: 21px;
  }

  #dot1, #dot2, #dot3, #dot4, #dot5, #dot6, #dot7, #dot8, #dot9, #dot10, #dot11, #dot12, #dot13, #dot14, #dot15, #dot16, #dot17, #dot18, #dot19, #dot20, #dot21, #dot22, #dot23, #dot24 {
      width: 72px;
      height: 16px;
      border-radius: 25px;
      margin-right: 8px;
      cursor: pointer;
      background-color: rgba(245, 245, 245, 0.3);
  }
}

.close2 {
  fill: white;
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 1500;
  cursor: pointer;
}

.disablescroll {
  overflow: hidden;
}

  @media(max-width: 850px) {
.references-infos {
      p {
          width: 70%;
          font-size: 26px;
          color: #5678AF;
          line-height: 38px;
      }
  }

  .refItem-text {
      flex-direction: column;
      margin: 20px 40px;
  }

  .refItem-text__nav {
      padding: 20px 0px;
  }

  .refItem-text__text {
      width: 95%;
  }

  .year {
 width: 40px;
 text-align:left;
 font-size: 12px;
}

.name{
 width: 110px;
 text-align:left;
 font-size: 12px;
 font-weight: 700;
}

.subject {
text-align:left;
font-size: 12px;
}

  .lightbox {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    border-collapse: collapse;
    box-sizing: border-box;
    margin: 0px 10px;
    padding: 0px 20px;
    margin-bottom: 40px;

    thead {
        tr {
            th {
                color: #4E74B7;
            }
        }
    }


    tbody {
        tr {
            border-bottom: 1px solid #DEDEDE;
            td {
padding: 16px 0px;
            }
        
    }

    }
    
}

  }


  @media (max-width: 600px) {
      .banner-image {
          text-align: center;
        h1 {
            margin: 0;
            font-size: 26px;
            z-index: 5;
        }
        h2 {
            margin: 0;
            margin-top: 8px;
            font-size: 14px;
            font-weight: 200;
            z-index: 5;
        }
    }

    .reference-item__container {
        height: auto;
    }

    .refItem-text__description {
        display: none;
    }

    .refItem-text{
        padding-top: 120px;
    }

    .references-infos {
      p {
          width: 100%;
          font-size: 14px;
          color: #5678AF;
          line-height: 38px;
      }
  }

  .lightbox {
      width: 100%;
  }

  tbody {
        tr {
            border-bottom: 1px solid #DEDEDE;
            td {
padding: 12px 0px;
            }
            .name {
                padding-right: 0px !important;
            }
        
    }

    }


.year {
 width: 50px;
 text-align:left;
 font-size: 12px;
 padding-right: 8px;
}

.name{
 width: 140px;
 padding: 0px 20px;
 text-align:left;
 font-size: 12px;
 font-weight: 700;
}

.subject {
text-align:left;
font-size: 12px;
}
  }


</style>
